html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
main, article, aside, canvas, details, figcaption, figure,
footer, header, menu, nav, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	font-weight: normal;
	font-style: normal;
	vertical-align: baseline;
	background: transparent;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	line-height: 1;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

main, article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
	display: block;
}

ul, ol {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ins {
	text-decoration: none;
}

mark {
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input, select {
	vertical-align: middle;
	margin: 0;
}

input, textarea {
	font-size: 100%;
	margin: 0;
	padding: 0;
}

input[type="button"], input[type="submit"],
input[type="reset"], button {
	outline: none;
	padding: 0;
	margin: 0;
}

img {
	vertical-align: top;
}
