// ============================================================
//  top
// ============================================================

//  main-image
// ------------------------------------------------------------

.main-img {
	position: relative;

	.main-img__slide {
		.main-img__img {
			width: 100%;
			background: no-repeat center center / cover;

			@include device-content(pc) {
				height: 670px;
			}

			@media only screen and (max-width: 1152px) {
				height: 500px;
			}

			@include device-content(sp) {
				height: auto;
			}

			img {
				@include device-content(sp) {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.slick-dots {
		position: absolute;
		left: 50%;
		display: flex;
		transform: translate(-50%, 0);

		@include device-content(pc) {
			bottom: 40px;
		}

		@media only screen and (max-width: 1152px) {
			bottom: 25px;
		}

		@include device-content(sp) {
			display: none !important;
		}

		li {
			margin: 0 4px;

			button {
				border: 0;
				border-radius: 0;
				padding: 0;
				margin: 0;
				background: #fff;
				text-indent: -9999px;

				@include device-content(pc) {
					width: 13px;
					height: 13px;
				}

				@media only screen and (max-width: 1152px) {
					width: 10px;
					height: 10px;
				}
			}

			&.slick-active {
				button {
					background: #0f5fb9;
					color: #fff;
				}
			}
		}
	}
}

//  top-search
// ------------------------------------------------------------

.top-search {
	@include device-content(pc) {
		margin-top: 65px;
	}

	@include device-content(sp) {
		background: #f6f6f6;
		padding: 20px 0;
	}

	.sec-header {
		@include device-content(pc) {
			margin-bottom: 35px;
		}

		@include device-content(sp) {
			margin-bottom: 23px;
		}
	}

	.top-search__body {
		@include device-content(pc) {
			background: url("../../img/top/bg_search.jpg") no-repeat center / cover;
			padding: 40px 0 25px;
		}

		@media only screen and (max-width: 1152px) {
			padding: 40px 10px 25px;
		}

		@include device-content(sp) {
			padding: 0 10px;
		}
	}

	.top-search-tab,
	.top-search-content,
	.top-search-keyword {
		@include device-content(pc) {
			width: 1065px;
			margin: 0 auto;
		}

		@media only screen and (max-width: 1152px) {
			width: auto;
		}
	}

	.top-search-tab {
		display: flex;
		background: #fff;
		border-bottom: 1px solid #1063b7;

		.top-search-tab__item {
			//width: 33.3333%;
			width: 50%;
			border-left: 1px solid #1063b7;

			&:first-child {
				border-left: 0;
			}

			a {
				display: block;
				color: #0f63b7;
				background: #fff;
				box-sizing: border-box;
				text-align: center;

				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 22px);
					height: 88px;
					padding-top: 23px;
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 11px);
					padding: 5px 0;
				}

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}

				.top-search-tab__en {
					display: block;

					@include device-content(pc) {
						@include device-prop-rem(font-size, pc, 16px);
						margin-top: 5px;
					}

					@include device-content(sp) {
						@include device-prop-rem(font-size, sp, 9px);
						margin-top: 3px;
					}
				}
			}

			&.ui-state-active {
				a {
					background: #0f61b5;
					color: #fff;
					outline: none;
					position: relative;

					&:hover {
						opacity: 1;
					}

					&:before {
						display: block;
						position: absolute;
						content: "";
						width: 0;
						height: 0;
						left: 50%;
						transform: translate(-50%, 0);
						border-style: solid;
						border-color: #155fb3 transparent transparent transparent;

						@include device-content(pc) {
							bottom: -14px;
							border-width: 14px 12px 0 12px;
						}

						@include device-content(sp) {
							bottom: -8px;
							border-width: 8px 7px 0 8px;
						}
					}
				}
			}
		}
	}

	.top-search-content {
		background: #fff;

		.top-search-list {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			@include device-content(pc) {
				padding: 25px 0;
			}

			@include device-content(sp) {
				padding: 5px 0 5px 10px;
			}

			.top-search-list__item {
				box-sizing: border-box;

				@include device-content(pc) {
					width: 33.3333%;
					padding: 0 25px;
				}

				@include device-content(sp) {
					width: 50%;
					padding: 0 10px;
				}

				a {
					display: block;
					line-height: 1.2;
					position: relative;
					color: #333;

					@include device-content(pc) {
						@include device-prop-rem(font-size, pc, 18px);
						padding: 18px 10px 18px 18px;
						border-bottom: 1px solid #ccc;
					}

					@include device-content(sp) {
						@include device-prop-rem(font-size, sp, 10px);
						padding: 8px 0;
					}

					&:hover {
						text-decoration: none;
						opacity: 0.6;
					}

					&:after {
						position: absolute;
						content: "";
						background: url("../../img/common/icn_arrow_right_01.png") no-repeat left top;

						right: 10px;
						top: 50%;
						transform: translate(0, -50%);

						@include device-content(pc) {
							width: 8px;
							height: 13px;
						}

						@include device-content(sp) {
							background-size: 4px auto;
							width: 4px;
							height: 7px;
						}
					}
				}
			}
		}
	}

	.top-search-keyword {
		display: flex;
		align-items: center;
		justify-content: center;

		@include device-content(pc) {
			margin: 25px auto 0;
		}

		@include device-content(sp) {
			margin: 15px 30px 0;
		}

		.top-search-keyword__input {
			text-align: left;
			box-sizing: border-box;
			border: 1px solid #ccc;
			outline: none;

			@include device-content(pc) {
				width: 460px;
				height: 60px;
				@include device-prop-rem(padding, pc, 0 10px);
				@include device-prop-rem(font-size, pc, 18px);
			}

			@include device-content(sp) {
				width: calc(100% - 30px);
				height: 30px;
				@include device-prop-rem(padding, sp, 0 10px);
				@include device-prop-rem(font-size, sp, 10px);
			}
		}

		.top-search-keyword__btn {
			background: #0f62b6;
			border: 0;
			border-radius: 0;
			text-align: center;
			box-sizing: border-box;
			outline: none;

			@include device-content(pc) {
				width: 60px;
				height: 60px;
			}

			@include device-content(sp) {
				width: 30px;
				height: 30px;
			}

			img {
				@include device-content(sp) {
					width: 11px;
					height: auto;
				}
			}

			&:hover {
				opacity: 0.6;
			}
		}
	}
}

//  top-support
// ------------------------------------------------------------

.top-support {
	@include device-content(pc) {
		margin-top: 80px;
	}

	@include device-content(sp) {
		margin-top: 40px;
	}

	.sec-header {
		@include device-content(pc) {
			margin-bottom: 70px;
		}

		@include device-content(sp) {
			margin-bottom: 35px;
		}
	}

	.top-support-list {
		.top-support-list__item {
			@include clearfix;

			@include device-content(pc) {
				margin-top: 70px;
			}

			@include device-content(sp) {
				margin-top: 35px;
			}

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.top-support-list__title {
		color: #0f63b7;
		text-align: center;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 24px);
			margin-bottom: 30px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 11px);
			margin-bottom: 15px;
		}

		span {
			display: inline-block;
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				height: 1px;
				top: 50%;
				background: #1063b7;

				@include device-content(pc) {
					width: 45px;
				}

				@include device-content(sp) {
					width: 50%;
				}
			}

			&:before {
				@include device-content(pc) {
					left: -52px;
				}

				@include device-content(sp) {
					left: -52%;
				}
			}

			&:after {
				@include device-content(pc) {
					right: -52px;
				}

				@include device-content(sp) {
					right: -52%;
				}
			}
		}
	}

	.top-support-list__img {
		@include device-content(pc) {
			float: left;
		}

		@include device-content(sp) {
			margin-bottom: 10px;
			text-align: center;
		}

		img {
			@include device-content(sp) {
				width: 50%;
				height: auto;
			}
		}
	}

	.top-support-list__desc {
		line-height: 1.75;

		@include device-content(pc) {
			margin-left: 350px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 10px);
			padding: 0 5px;
		}
	}
}

//  bg-trace
// ------------------------------------------------------------

.bg-trace {
	margin-top: 80px;
	padding: 50px 0 75px;
	background: url("../../img/top/bg_case.jpg") no-repeat center center / cover;

	@include device-content(pc) {
		margin-top: 80px;
		padding: 50px 0 75px;
	}

	@include device-content(sp) {
		margin-top: 40px;
		padding: 25px 0 15px;
	}
}

//  top-topics
// ------------------------------------------------------------

.top-topics {
	.sec-header {
		margin-bottom: 20px;
	}

	.top-topics-list {
		position: relative;

		@include device-content(pc) {
			margin: 0 -13px;
		}

		@media only screen and (max-width: 1152px) {
			margin: 0;
		}

		.slick-slide {
			@include device-content(pc) {
				width: 350px;
				margin: 0 13px;
			}

			@include device-content(sp) {
				margin: 0 72px;
			}

			a {
				color: #333;
				display: block;

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.slick-arrow {
			position: absolute;
			transform: (0, -50%);
			text-indent: -9999px;
			border: 0;

			@include device-content(pc) {
				top: 130px;
			}

			@include device-content(sp) {
				top: 28%;
			}

			&:hover {
				opacity: 0.6;
				cursor: pointer;
			}

			&.slick-prev {
				background: transparent url("../../img/top/btn_slide_right.png") no-repeat left center;

				@include device-content(pc) {
					left: -20px;
					width: 17px;
					height: 26px;
				}

				@media only screen and (max-width: 1152px) {
					left: -10px;
					width: 17px;
					height: 26px;
				}

				@include device-content(sp) {
					left: 35px;
					width: 8px;
					height: 13px;
					background-size: 8px auto;
				}
			}

			&.slick-next {
				background: transparent url("../../img/top/btn_slide_left.png") no-repeat left center;
				width: 17px;
				height: 26px;

				@include device-content(pc) {
					right: -20px;
					width: 17px;
					height: 26px;
				}

				@media only screen and (max-width: 1152px) {
					right: -10px;
					width: 17px;
					height: 26px;
				}

				@include device-content(sp) {
					right: 35px;
					width: 8px;
					height: 13px;
					background-size: 8px auto;
				}
			}
		}
	}

	.top-topics-list__img {
		margin-bottom: 10px;
		background: #fff;
		border: 1px solid #ccc;
		box-sizing: border-box;
	}

	.top-topics-list__title {
		text-align: center;
		font-weight: bold;
		line-height: 1.4;
		color: #0f63b7;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 11px);
			margin-bottom: 10px;
		}
	}

	.top-topics-list__desc {
		line-height: 1.375;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 16px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 10px);
		}
	}
}

//  top-case
// ------------------------------------------------------------

.top-case {
	@include device-content(pc) {
		margin-top: 60px;
		display: flex;
		align-items: center;
	}

	@include device-content(sp) {
		margin-top: 30px;
	}

	.sec-header {
		@include device-content(pc) {
			width: 425px;
			margin-right: auto;
		}

		@include device-content(sp) {
			margin-bottom: 15px;
		}
	}

	.top-case-list {
		@include device-content(pc) {
			width: 625px;
		}

		.top-case-list__item {
			@include device-content(pc) {
				margin-top: 25px;
			}

			@include device-content(sp) {
				margin-top: 13px;
			}

			&:first-child {
				margin-top: 0;
			}

			a {
				display: block;
				box-sizing: border-box;
				background: #1965ba;
				color: #fff;
				position: relative;

				@include device-content(pc) {
					padding: 20px 25px;
					@include device-prop-rem(font-size, pc, 18px);
				}

				@include device-content(sp) {
					padding: 10px 12px;
					@include device-prop-rem(font-size, sp, 10px);
				}

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}

				&:after {
					content: "";
					background: url("../../img/common/icn_arrow_right_04.png") no-repeat left top;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);

					@include device-content(pc) {
						width: 8px;
						height: 16px;
						right: 25px;
					}

					@include device-content(sp) {
						width: 4px;
						height: 8px;
						background-size: 4px auto;
						right: 10px;
					}
				}
			}
		}
	}
}

//  top-news
// ------------------------------------------------------------

.top-news {
	@include device-content(pc) {
		display: flex;
		margin-top: 95px;
	}

	@include device-content(sp) {
		padding: 15px 0;
	}

	.sec-header {
		margin-right: auto;
		background: #0f63b9;
		color: #fff;
		box-sizing: border-box;

		@include device-content(pc) {
			width: 14%;
			height: 155px;
			padding: 50px 0 0;
		}

		@include device-content(sp) {
			padding: 13px 0;
			margin: 0 40px;
		}

		.sec-title {
			font-weight: bold;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 22px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 11px);
			}
		}

		.sec-title__link {
			a {
				color: #fff;

				&:after {
					background-image: url("../../img/common/icn_arrow_right_05.png");
				}
			}
		}
	}

	.top-news-list {
		@include device-content(pc) {
			width: 82.2%;
		}

		@include device-content(sp) {
			margin-top: 15px;
			@include device-prop-rem(font-size, sp, 10px);
		}

		.top-news-list__item {
			border-bottom: 1px solid #dcdcdc;

			a,
			.top-news-list__nolink {
				display: block;
				position: relative;
				line-height: 1.5;

				@include device-content(pc) {
					display: flex;
					padding: 14px 5px;
				}

				@include device-content(sp) {
					padding: 6px 8px;
				}

				&.-link {
					&:after {
						position: absolute;
						content: "";
						top: 50%;
						transform: translate(0, -50%);
						background-image: url("../../img/common/icn_arrow_right_06.png");

						@include device-content(pc) {
							width: 8px;
							height: 15px;
							right: 15px;
						}

						@include device-content(sp) {
							background-size: 4px auto;
							width: 4px;
							height: 8px;
							right: 8px;
						}
					}
				}
			}

			a {
				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}

			.top-news-list__date {
				color: #1561b7;
				font-weight: bold;

				@include device-content(pc) {
					width: 16.4%;
					margin-right: 5px;
				}
			}

			.top-news-list__cat {
				width: 9.8%;
				text-align: center;
				margin-right: 10px;

				@include device-content(sp) {
					display: none;
				}

				span {
					display: block;
					background: #333;
					color: #fff;
					@include device-prop-rem(font-size, pc, 14px);
					padding: 2px 5px;
				}
			}

			.top-news-list__title {
				color: #535353;

				@include device-content(pc) {
					width: 69.2%;
				}

				@include device-content(sp) {
					padding-right: 10px;
				}
			}
		}
	}
}

//  top-about
// ------------------------------------------------------------

.top-about {
	@include device-content(pc) {
		margin-top: 65px;
	}

	@include device-content(sp) {
		margin-top: 30px;
	}

	.sec-header {
		@include device-content(pc) {
			margin-bottom: 45px;
		}

		@include device-content(sp) {
			margin-bottom: 22px;
		}
	}

	.top-about-list {
		@include device-content(pc) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -16px -32px;
		}

		@include device-content(sp) {
			margin: 0;
		}

		.top-about-list__item {
			@include device-content(pc) {
				width: 33.3333%;
				padding: 0 16px;
				margin-bottom: 32px;
				box-sizing: border-box;
				text-align: center;
			}

			@include device-content(sp) {
				padding: 6px 8px;
				border-bottom: 1px solid #ddd;
			}

			a {
				color: #333;
				display: block;
				position: relative;

				@include device-content(sp) {
					display: flex;
					align-items: center;
				}

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}

				&:after {
					@include device-content(sp) {
						position: absolute;
						content: "";
						top: 50%;
						transform: translate(0, -50%);
						background-image: url("../../img/common/icn_arrow_right_06.png");
						background-size: 4px auto;
						width: 4px;
						height: 8px;
						right: 0;
					}
				}
			}
		}

		.top-about-list__img {
			@include device-content(pc) {
				margin-bottom: 20px;
			}

			@include device-content(sp) {
				width: 75px;
				margin-right: 15px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.top-about-list__title {
			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 24px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 12px);
			}
		}
	}
}

//  top-recruit
// ------------------------------------------------------------

.top-recruit {
	@include device-content(pc) {
		margin-top: 120px;
	}

	@include device-content(sp) {
		margin-top: 35px;
	}

	.sec-header {
		@include device-content(pc) {
			margin-bottom: 55px;
		}

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}

	.top-recruit-list {
		display: flex;
		justify-content: center;

		.top-recruit-list__item {
			text-align: center;

			@include device-content(pc) {
				width: 400px;
				margin: 0 10px;
			}

			@include device-content(sp) {
				width: 50%;
			}

			a {
				color: #333;
				display: block;

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}
		}

		.top-about-list__img {
			@include device-content(pc) {
				margin-bottom: 20px;
			}

			@include device-content(sp) {
				margin-bottom: 10px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.top-about-list__title {
			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 24px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 12px);
			}

		}
	}
}

// ============================================================
//  contact
// ============================================================

.page-contact {
	.contact-desc {
		line-height: 1.5;
		text-align: center;

		@include device-content(pc) {
			margin-top: 50px;
		}

		@include device-content(sp) {
			margin-top: 25px;
		}

		&:before {
			content: "●";
			color: #fd8124;
		}
	}

	.contact-sec {
		@include device-content(pc) {
			margin-top: 50px;
		}

		@include device-content(sp) {
			margin-top: 25px;
		}

		.contact-sec__header {
			position: relative;
			text-align: center;

			@include device-content(pc) {
				margin-bottom: 40px;
			}

			@include device-content(sp) {
				margin-bottom: 20px;
			}

			&:before {
				width: 100%;
				height: 2px;
				content: "";
				display: block;
				position: absolute;
				z-index: 5;
				top: 50%;
				background: #aaa;
			}
		}

		.contact-sec__title {
			display: inline-block;
			background: #fff;
			padding: 0 10px;
			color: #0f63b6;
			position: relative;
			z-index: 10;
			font-weight: bold;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 22px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 22px * .75);
			}
		}
	}

	.action__btn {
		@include device-content(pc) {
			width: 480px;
		}

		@include device-content(sp) {
			width: 100%;
		}
	}
}

.contact-form-desc {
	text-align: center;
	color: #0f62b8;
	line-height: 1.35;

	@include device-content(pc) {
		margin-top: 60px;
		margin-bottom: 30px;
	}

	@include device-content(sp) {
		margin-top: 30px;
		margin-bottom: 15px;
	}
}

// ============================================================
//  page-news
// ============================================================

.page-news {
	.news-row {
		@include device-content(pc) {
			display: flex;
			flex-wrap: wrap;
		}

		&.-single {
			@include device-content(pc) {
				display: block;
			}

			.news-content {
				@include device-content(pc) {
					width: auto;
				}
			}
		}
	}

	.news-content {
		@include device-content(pc) {
			width: 800px;
			margin-right: auto;
		}
	}

	.news-side {
		@include device-content(pc) {
			width: 220px;
		}
	}
}

.news-detail {
	line-height: 1.8;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 16px);
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 16px * .75);
	}

	p {
		margin-top: 1.5em;

		&:first-child {
			margin-top: 0;
		}
	}

	.alignnone {
		margin: 0 20px 20px 0;
	}

	.aligncenter {
		display: block;
		margin: 0 auto;
	}

	div {
		&.aligncenter {
			display: block;
			margin: 0 auto 0 auto;
		}
	}

	.alignright {
		float: right;
		margin: 0 0 20px 20px;
	}

	.alignleft {
		float: left;
		margin: 0 20px 20px 0;
	}

	a {
		img {
			&.alignright {
				float: right;
				margin: 0 0 20px 20px;
			}

			&.alignnone {
				margin: 0 20px 20px 0;
			}

			&.alignleft {
				float: left;
				margin: 0 20px 20px 0;
			}

			&.aligncenter {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.wp-caption {
		background: #fff;
		border: 1px solid #f0f0f0;
		max-width: 96%;
		padding: 0 3px 10px;
		text-align: center;

		img {
			border: 0 none;
			height: auto;
			margin: 0;
			max-width: 98.5%;
			padding: 0;
			width: auto;
		}

		p {
			&.wp-caption-text {
				font-size: 11px;
				line-height: 17px;
				margin: 0;
				padding: 0 4px 5px;
			}
		}
	}

	.wp-caption {
		&.alignnone {
			margin: 0 20px 20px 0;
		}

		&.alignleft {
			margin: 0 20px 20px 0;
		}

		&.alignright {
			margin: 0 0 20px 20px;
		}
	}

	.event-archive {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #cbcbcb;

		@include device-content(sp) {
			display: block;
			border: none;
		}

		tbody {
			@include device-content(sp) {
				display: block;
			}
		}

		tr {
			@include device-content(sp) {
				display: block;
			}
		}

		th, td {
			padding: 10px 15px;
			border: 1px solid #cbcbcb;

			@include device-content(sp) {
				display: block;
				border: none;
				padding: 0;
			}
		}

		th {
			text-align: left;
			background: #d6edce;
			width: 150px;

			@include device-content(sp) {
				width: auto;
				background: none;
				font-weight: bold;
				color: #39733f;
				@include device-prop-rem(font-size, sp, 18px * .75);
			}
		}

		td {
			@include device-content(sp) {
				margin-bottom: 1em;
			}
		}
	}
}

.news-list {
	.news-list__item {
		border-bottom: 1px dotted #154083;
		position: relative;

		@include device-content(pc) {
			padding: 15px 40px 15px 0;
		}

		@include device-content(sp) {
			padding: 8px 20px 8px 0;
		}

		&.-link {
			&:after {
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				background: url("../../img/news/icn_arrow_right_01.png") no-repeat;
				content: "";

				@include device-content(pc) {
					right: 15px;
					width: 10px;
					height: 16px;
				}

				@include device-content(sp) {
					right: 8px;
					width: 5px;
					height: 8px;
					background-size: 5px auto;
				}
			}
		}
	}

	.news-list__meta {
		display: flex;
		align-items: center;

		@include device-content(pc) {
			margin-bottom: 10px;
		}

		@include device-content(sp) {
			margin-bottom: 5px;
		}
	}

	.news-list__cat {
		color: #fff;
		background: #333;
		display: inline-block;

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 14px);
			padding: 4px 20px;
			margin-right: 10px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px * .75);
			padding: 3px 10px;
			margin-right: 5px;
		}
	}

	.news-list__time {
		display: inline-block;
		color: #0f63b8;
	}

	.news-list__title {
		line-height: 1.5;

		a {
			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}
		}
	}
}

.news-side {
	@include device-content(sp) {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
	}

	.news-side-sec {
		@include device-content(pc) {
			margin-top: 70px;
		}

		@include device-content(sp) {
			margin-top: 0;
			width: 50%;
		}

		&:first-child {
			margin-top: 0;
		}

		.news-side-sec__title {
			font-weight: bold;
			color: #0f63b8;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 18px);
				margin-bottom: 20px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 18px * .75);
				margin-bottom: 10px;
			}

		}
	}

	.news-side-list {
		li {
			line-height: 1.5;

			@include device-content(pc) {
				margin-bottom: 10px;
			}

			@include device-content(sp) {
				margin-bottom: 5px;
			}

			a {
				display: block;
				background: url("../../img/news/icn_arrow_right_02.png") no-repeat left center;

				@include device-content(pc) {
					padding-left: 20px;
				}

				@include device-content(sp) {
					padding-left: 10px;
				}

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}
		}
	}
}

// ============================================================
//  page-product-list
// ============================================================

.product-search {
	@include device-content(pc) {
		margin-top: 50px;
	}

	@include device-content(sp) {
		margin-top: 25px;
	}

	.product-search__tab {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #0f62b8;

		a {
			display: block;
			outline: 0;
		}

		@include device-content(sp) {
			padding: 0 10px;
		}

		li {
			margin-left: -1px;
			position: relative;

			@include device-content(sp) {
				width: 50%;
			}

			&.ui-state-active {
				a {
					color: #fff;
					background: #0f62b6;
				}

				&:after {
					display: inline-block;
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					left: 50%;
					transform: translate(-50%, 0);
					border-style: solid;
					border-width: 12px 13px 0 13px;
					border-color: #0f62b6 transparent transparent transparent;
				}
			}

			&:first-child {
				margin-left: 0;
			}

			a {
				display: block;
				color: #0f62b6;
				background: #fff;
				border: 1px solid #0f62b6;
				border-bottom: none;
				text-align: center;

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}

				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 22px);
					padding: 30px 0;
					width: 355px;
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 22px * .6);
					padding: 15px 0;
					width: 100%;
				}
			}
		}
	}

	.product-search__desc {
		text-align: center;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 16px);
			margin: 40px 0;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 16px * .6);
			margin: 20px 0;
		}
	}

	.product-search__result {
		text-align: center;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
			margin: 70px 0 60px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 26px * .6);
			margin: 35px 0 30px;
		}
	}
}

.product-list {
	margin: 0 auto;

	@include device-content(pc) {
		width: 91%;
	}

	@include device-content(sp) {
		width: 100%;
	}

	.product-list__item {
		border-bottom: 1px solid #ddd;

		@include device-content(pc) {
			padding: 40px 0;
		}

		@include device-content(sp) {
			padding: 20px 0;
		}

		a {
			display: block;
			position: relative;

			&:after {
				content: "";
				background: url("../../img/product/icn_arrow_right.png") no-repeat;
				width: 16px;
				height: 26px;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translate(0, -50%);

				@include device-content(sp) {
					display: none;
				}
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}
		}

		&:first-child {
			padding-top: 0;
		}

		&:after {
			width: 16px;
			height: 26px;
			content: "";
			right: 15px;
			top: 50%;
			transform: translate(0, -50%);
			background: url("../../img/product/icn_arrow_right.png") no-repeat;
		}

		.product-list__title {
			color: #0f63b8;
			font-weight: bold;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 26px);
				margin-bottom: 20px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 26px * .6);
				margin-bottom: 10px;
			}
		}

		.product-list__data {
			@include device-content(pc) {
				display: flex;
			}
		}

		.product-list__img {
			@include device-content(pc) {
				width: 36%;
				margin-right: auto;
			}

			@include device-content(sp) {
				margin-bottom: 15px;
			}

			img {
				border: 1px solid #ccc;
				box-sizing: border-box;
				width: 100%;
				height: auto;
			}
		}

		.product-list__text {
			box-sizing: border-box;

			@include device-content(pc) {
				width: 61%;
				padding-right: 60px;
				line-height: 1.75;
			}

			@include device-content(sp) {
				line-height: 1.5;
			}

			p {
				@include device-content(pc) {
					margin-bottom: 20px;
				}

				@include device-content(sp) {
					margin-bottom: 10px;
				}
			}
		}
	}

	.product-list__tag {
		display: flex;
		flex-wrap: wrap;
		margin: 15px -3px 0;

		li {
			@include device-content(pc) {
				min-width: 180px;
				margin: 0 3px 6px;
				@include device-prop-rem(font-size, pc, 14px);
			}

			@include device-content(sp) {
				min-width: 20px;
				margin: 0 3px 6px;
				@include device-prop-rem(font-size, sp, 14px * .6);
			}

			span {
				display: block;
				background: #1063b8;
				color: #fff;
				text-align: center;

				@include device-content(pc) {
					padding: 8px 10px;
				}

				@include device-content(sp) {
					padding: 4px 8px;
				}
			}
		}
	}
}

.product-search-cond {
	display: flex;
	justify-content: center;

	.product-search-cond__item {
		text-align: center;
		box-sizing: border-box;

		@include device-content(pc) {
			width: 300px;
			margin: 0 25px;
		}

		@include device-content(sp) {
			width: 50%;
			margin: 0 10px;
		}

		.form-select {
			width: 100%;

			.customSelect {
				overflow: hidden;

				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 19px);
					height: 45px;
					line-height: 44px;
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 19px * .6);
					height: 35px;
					line-height: 34px;
				}
			}
		}
	}

	.product-search-cond__title {
		color: #0f63b6;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
			margin-bottom: 8px;
		}
	}
}

.product-search-keyword {
	display: flex;
	align-items: center;
	justify-content: center;

	@include device-content(pc) {
		margin: 40px auto 0;
	}

	@include device-content(sp) {
		margin: 20px 30px 0;
	}

	.product-search-keyword__input {
		text-align: left;
		box-sizing: border-box;
		border: 1px solid #ccc;
		outline: none;

		@include device-content(pc) {
			width: 460px;
			height: 50px;
			@include device-prop-rem(padding, pc, 0 10px);
			@include device-prop-rem(font-size, pc, 18px);
		}

		@include device-content(sp) {
			width: calc(100% - 30px);
			height: 30px;
			@include device-prop-rem(padding, sp, 0 10px);
			@include device-prop-rem(font-size, sp, 10px);
		}
	}

	.product-search-keyword__btn {
		background: #0f62b6;
		border: 0;
		border-radius: 0;
		text-align: center;
		box-sizing: border-box;
		outline: none;

		@include device-content(pc) {
			width: 50px;
			height: 50px;
		}

		@include device-content(sp) {
			width: 30px;
			height: 30px;
		}

		img {
			@include device-content(sp) {
				width: 11px;
				height: auto;
			}
		}

		&:hover {
			opacity: 0.6;
		}
	}
}

// ============================================================
//  page-product-detail
// ============================================================

.page-product-detail {
	.page__title {
		@include device-content(pc) {
			margin-bottom: 20px;
		}

		@include device-content(sp) {
			margin-bottom: 10px;
		}
	}
}

.product-cat {
	text-align: center;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 26px);
		margin-top: 50px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 26px * .6);
		margin-top: 25px;
	}
}

.product-tag {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	@include device-content(pc) {
		margin-top: 20px;
	}

	@include device-content(sp) {
		margin-top: 10px;
	}

	li {
		margin: 0 2px 4px;

		@include device-content(pc) {
			width: 170px;
			@include device-prop-rem(font-size, pc, 14px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px * .6);
		}

		a {
			display: block;
			background: #ededed;

			@include device-content(pc) {
				padding: 8px 0;
			}

			@include device-content(sp) {
				padding: 8px 15px;
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}
		}
	}
}

.product-new {
	text-align: center;

	span {
		display: inline-block;
		background: #fd8224;
		color: #fff;

		@include device-content(pc) {
			width: 170px;
			padding: 5px 10px;
		}

		@include device-content(sp) {
			padding: 4px 8px;
		}
	}
}

.product-data {
	@include device-content(pc) {
		margin-top: 40px;
		display: flex;
	}

	@include device-content(sp) {
		margin-top: 20px;
	}

	.product-img {
		margin-right: auto;
		text-align: center;
		border: 1px solid #bababa;

		@include device-content(pc) {
			width: 54.2%;
		}

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 380px;
		}
	}

	.product-text {
		line-height: 1.75;

		@include device-content(pc) {
			width: 42.27%;
		}

		@include device-content(sp) {
			margin-top: 10px;
		}

		.product-text__top {
			font-weight: bold;
			margin-bottom: 1em;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 18px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 18px * .6);
			}
		}
	}
}

.product-link {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	@include device-content(pc) {
		margin-top: 40px;
	}

	@include device-content(sp) {
		margin-top: 20px;
	}

	.produce-link__item {
		margin: 0 5px 10px;
		display: flex;
		box-sizing: border-box;
		align-items: center;
		justify-content: center;
		color: #fff;
		position: relative;
		font-weight: bold;

		@include device-content(pc) {
			width: calc(20% - 10px);
			height: 65px;
			@include device-prop-rem(font-size, pc, 18px);
		}

		@include device-content(sp) {
			width: 45%;
			height: 35px;
			@include device-prop-rem(font-size, sp, 18px * .6);
		}

		&:after {
			position: absolute;
			content: "";
			background: no-repeat left top;
		}

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}

		&.-maker {
			background: #444;

			&:after {
				background-image: url("../../img/product/icn_maker.png");
				right: 10px;
				top: 5px;

				@include device-content(pc) {
					width: 23px;
					height: 22px;
				}

				@include device-content(sp) {
					width: 12px;
					height: 11px;
					background-size: 12px auto;
				}
			}
		}

		&.-pdf {
			background: #b10e12;

			@include device-content(pc) {
				padding-right: 20px;
			}

			@include device-content(sp) {
				padding-right: 10px;
			}

			&:after {
				background-image: url("../../img/product/icn_pdf.png");
				right: 10px;
				top: 50%;
				transform: translate(0, -50%);

				@include device-content(pc) {
					width: 34px;
					height: 44px;
				}

				@include device-content(sp) {
					width: 17px;
					height: 22px;
					background-size: 17px auto;
				}
			}
		}
	}
}

.product-slider {
	@include clearfix;

	@include device-content(pc) {
		margin: 50px -5px;
	}

	@include device-content(sp) {
		margin: 25px -5px;
		padding: 0 30px;
	}

	a {
		outline: 0;
		display: block;

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	&.-border {
		img {
			border: 1px solid #ccc;
		}
	}

	.product-slide__desc {
		text-align: center;

		@include device-content(pc) {
			margin-top: 15px;
		}

		@include device-content(sp) {
			margin-top: 8px;
		}
	}

	.slick-slide {
		margin: 0 5px;
	}

	.slick-arrow {
		position: absolute;
		transform: (0, -50%);
		text-indent: -9999px;
		border: 0;

		@include device-content(pc) {
			top: 110px;
		}

		@include device-content(sp) {
			top: calc(50% - 25px);
		}

		&:hover {
			opacity: 0.6;
			cursor: pointer;
		}

		&.slick-prev {
			background: transparent url("../../img/top/btn_slide_right.png") no-repeat left center;

			@include device-content(pc) {
				left: -40px;
				width: 17px;
				height: 26px;
			}

			@include device-content(sp) {
				left: 10px;
				width: 8px;
				height: 13px;
				background-size: 8px auto;
			}
		}

		&.slick-next {
			background: transparent url("../../img/top/btn_slide_left.png") no-repeat left center;
			width: 17px;
			height: 26px;

			@include device-content(pc) {
				right: -40px;
				width: 17px;
				height: 26px;
			}

			@include device-content(sp) {
				right: 10px;
				width: 8px;
				height: 13px;
				background-size: 8px auto;
			}
		}
	}
}

.product-main {
	@include device-content(pc) {
		margin: 0 0 50px;
	}

	@include device-content(sp) {
		margin: 0 0 25px;
	}
}

.product-movie {
	background: #eee;

	@include device-content(pc) {
		padding: 50px 0 70px;
	}

	@include device-content(sp) {
		padding: 25px 0 35px;
	}

	.product-movie__wrapper {
		&.-count-2,
		&.-count-3 {
			@include device-content(pc) {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.product-movie__cnt {
					&:nth-child(1),
					&:nth-child(2) {
						width: 50%;
					}

					&:nth-child(3) {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}

		iframe {
			@include device-content(sp) {
				width: 100%;
			}
		}
	}

	.product-movie__cnt {
		text-align: center;

		+ .product-movie__cnt {
			@include device-content(sp) {
				margin-top: 20px;
			}
		}

		iframe {
			@include device-content(sp) {
				width: 100%;
			}
		}
	}
}

.product-feature {
	@include device-content(pc) {
		margin: 70px 0;
	}

	@include device-content(sp) {
		margin: 35px 0;
	}

	.product-feature-list {
		.product-feature-list__item {
			display: flex;

			@include device-content(pc) {
				margin-bottom: 45px;
			}

			@include device-content(sp) {
				margin-bottom: 22px;
			}
		}

		.product-feature-list__title {
			font-weight: bold;
		}

		.product-feature-list__img {
			@include device-content(pc) {
				margin-right: auto;
				width: 32.72%;
			}

			@include device-content(sp) {
				margin-right: 10px;
				flex: 1;
			}

			img {
				border: 1px solid #bbb;
				width: 100%;
				height: auto;
			}

			&.-no-border {
				img {
					border: 0;
				}
			}
		}

		.product-feature-list__text {
			line-height: 1.75;

			&:first-child {
				@include device-content(pc) {
					width: 100%;
				}
			}

			@include device-content(pc) {
				width: 64.54%;
			}

			@include device-content(sp) {
				flex: 2;
			}
		}
	}
}

.product-example {
	background: #ededed;

	@include device-content(pc) {
		padding: 70px 0;
	}

	@include device-content(sp) {
		padding: 35px 0;
	}

	.cnt {
		@include device-content(pc) {
			max-width: 750px;
		}
	}

	.product-example-text {
		line-height: 2;
	}
}

.product-spec {
	@include device-content(pc) {
		padding: 100px 0 0;
	}

	@include device-content(sp) {
		padding: 50px 0 0;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.product-spec__title {
		text-align: center;
		line-height: 1.25;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
			margin-bottom: 40px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 26px * .6);
			margin-bottom: 20px;
		}
	}

	.product-spec__text {
		line-height: 1.75;
	}
}

.product-spec-sec {
	@include device-content(pc) {
		margin-top: 50px;
	}

	@include device-content(sp) {
		margin-top: 25px;
	}

	&:first-child {
		margin-top: 0;
	}

	table {
		margin: 0 auto;
		border-collapse: collapse;
		border: 1px solid #333;

		&.slim {
			th, td {
				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 14px);
					padding: 5px 10px;
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 10px);
					padding: 5px;
				}
			}
		}

		th, td {
			line-height: 1.33;
			border: 1px solid #333;
			text-align: center;
			vertical-align: middle;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 20px);
				padding: 5px 20px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 20px * .6);
				padding: 5px 10px;
			}

			p {
				text-align: left;
				margin: 0;
				line-height: inherit;
			}
		}

		th {
			background: #d0edf7;
		}
	}

	p {
		text-align: center;
		margin-top: 1.5em;
		line-height: 1.5;

		&.slim {
			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 14px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 10px);
			}
		}
	}

	.product-spec-sec__title {
		text-align: center;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
			margin-bottom: 8px;
		}
	}

}

.product-free-layout {
	line-height: 1.8;

	h3 {
		text-align: center;
		font-weight: bold;
		position: relative;
		line-height: 1.25;

		@include device-content(pc) {
			margin-bottom: 60px;
			@include device-prop-rem(font-size, pc, 26px);
		}

		@include device-content(sp) {
			margin-bottom: 30px;
			@include device-prop-rem(font-size, sp, 26px * .6);
		}

		&:after {
			position: absolute;
			height: 1px;
			width: 58px;
			content: "";
			background: #0f62b8;
			left: 50%;
			bottom: -15px;
			transform: translate(-50%, 0);
		}
	}

	%first-child {
		margin-top: 1.5em;

		&:first-child {
			margin-top: 0;
		}
	}

	p {
		@extend %first-child;
	}

	ul {
		@extend %first-child;
		list-style: disc;
		padding-left: 1.5em;
	}

	dl {
		@extend %first-child;
		list-style: decimal;
		padding-left: 1.5em;
	}

	.alignnone {
		margin: 0 20px 20px 0;
	}

	.aligncenter {
		display: block;
		margin: 0 auto 0 auto;
	}

	div {
		&.aligncenter {
			display: block;
			margin: 0 auto 0 auto;
		}
	}

	.alignright {
		float: right;
		margin: 0 0 20px 20px;
	}

	.alignleft {
		float: left;
		margin: 0 20px 20px 0;
	}

	a {
		img {
			&.alignright {
				float: right;
				margin: 0 0 20px 20px;
			}

			&.alignnone {
				margin: 0 20px 20px 0;
			}

			&.alignleft {
				float: left;
				margin: 0 20px 20px 0;
			}

			&.aligncenter {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.wp-caption {
		background: #fff;
		border: 1px solid #f0f0f0;
		max-width: 96%;
		padding: 0 3px 10px;
		text-align: center;

		img {
			border: 0 none;
			height: auto;
			margin: 0;
			max-width: 98.5%;
			padding: 0;
			width: auto;
		}

		p {
			&.wp-caption-text {
				font-size: 11px;
				line-height: 17px;
				margin: 0;
				padding: 0 4px 0;
			}
		}
	}

	.wp-caption {
		&.alignnone {
			margin: 0 20px 20px 0;
		}

		&.alignleft {
			margin: 0 20px 20px 0;
		}

		&.alignright {
			margin: 0 0 20px 20px;
		}
	}
}

.product-contact {
	text-align: center;
	color: #0f63b8;
	line-height: 1.5;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 20px);
		margin-top: 60px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 20px * .6);
		margin-top: 30px;
	}

	.product-contact__title {
		font-weight: bold;
		margin-bottom: .75em;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
		}
	}

	.product-contact-link {
		display: flex;
		align-items: center;
		justify-content: center;

		.product-contact-link__item {
			margin: 0 8px;
			display: block;
			border: 1px solid #1063b8;
			position: relative;

			@include device-content(pc) {
				width: 360px;
				padding: 25px;
			}

			@include device-content(sp) {
				width: auto;
				padding: 10px 30px 10px 20px;
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			&:after {
				position: absolute;
				content: "";
				top: 50%;
				transform: translate(0, -50%);
				background: url("../../img/news/icn_arrow_right_01.png");

				@include device-content(pc) {
					right: 25px;
					width: 10px;
					height: 16px;
				}

				@include device-content(sp) {
					right: 10px;
					width: 5px;
					height: 8px;
					background-size: 5px auto;
				}
			}
		}
	}
}

.product-assoc {
	background: #eee;

	@include device-content(pc) {
		margin-top: 80px;
		padding: 40px 145px 60px;
	}

	@include device-content(sp) {
		margin-top: 40px;
		padding: 20px 40px 20px;
	}

	.product-sec__title {
		margin-bottom: 35px;
	}

	.product-assoc-slider {
		margin: 0 -5px;

		a {
			display: block;

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}
		}

		.product-assoc-slide__img {
			img {
				max-width: 100%;
				height: auto;
				border: 1px solid #bbb;
			}
		}

		.product-assoc-slide__text {
			margin-top: 15px;
			line-height: 1.5;
			text-align: center;
		}

		.slick-slide {
			margin: 0 5px;
		}

		.slick-arrow {
			position: absolute;
			transform: (0, -50%);
			text-indent: -9999px;
			border: 0;

			@include device-content(pc) {
				top: 75px;
			}

			@include device-content(sp) {
				top: calc(50% - 30px);
			}

			&:hover {
				opacity: 0.6;
				cursor: pointer;
			}

			&.slick-prev {
				background: transparent url("../../img/top/btn_slide_right.png") no-repeat left center;

				@include device-content(pc) {
					left: -65px;
					width: 17px;
					height: 26px;
				}

				@include device-content(sp) {
					left: -15px;
					width: 8px;
					height: 13px;
					background-size: 8px auto;
				}
			}

			&.slick-next {
				background: transparent url("../../img/top/btn_slide_left.png") no-repeat left center;
				width: 17px;
				height: 26px;

				@include device-content(pc) {
					right: -65px;
					width: 17px;
					height: 26px;
				}

				@include device-content(sp) {
					right: -15px;
					width: 8px;
					height: 13px;
					background-size: 8px auto;
				}
			}
		}
	}
}

.product-fixed-contact {
	position: fixed;
	right: 0;
	text-align: center;
	width: 300px;
	//height: 194px;
	padding: 15px;
	box-sizing: border-box;
	background: #fff;
	line-height: 1.571428571;
	border: 1px solid #ccc;
	border-right: 0;
	z-index: 50;
	transform: translateY(-1px);
	@include device-prop-rem(font-size, pc, 14px);

	@include device-content(sp) {
		display: none;
	}

	.product-fixed-contact__title {
		font-weight: bold;
		margin-bottom: 3px;
	}

	.product-fixed-contact__text {
		font-weight: bold;
		color: #0f63b7;
		margin-bottom: 8px;
	}

	.product-fixed-contact__action {
		a {
			display: block;
			background: #0f62b7;
			color: #fff;
			box-sizing: border-box;
			height: 41px;
			line-height: 41px;
			padding-left: 25px;
			position: relative;

			&:before {
				position: absolute;
				display: block;
				background: url("../../img/common/icn_mail_02.png");
				width: 19px;
				height: 12px;
				content: "";
				left: 14px;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}
		}
	}
}

// ============================================================
//  page-product-pickup
// ============================================================

.page-product-pickup {
	.page__title {
		@include device-content(pc) {
			margin-bottom: 20px;
		}

		@include device-content(sp) {
			margin-bottom: 10px;
		}
	}

	.com-contact {
		border-bottom: 1px solid #1560b4;

		@include device-content(pc) {
			padding-bottom: 40px;
			margin-bottom: -30px;
		}

		@include device-content(sp) {
			padding-bottom: 20px;
			margin-bottom: -15px;
		}
	}

	.product-spec {
		@include device-content(pc) {
			padding: 90px 0 20px;
		}

		@include device-content(sp) {
			padding: 40px 0 10px;
		}

		.product-sec__title {
			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 26px);
				margin-bottom: 70px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 26px * .6);
				margin-bottom: 30px;
			}

			&:after {
				display: none;
			}
		}
	}
}

.product-pickup {
	margin-top: 60px;

	.product-pickup__tab {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #0f62b8;

		a {
			display: block;
			outline: 0;
		}

		@media only screen and (max-width: 1099px) {
			padding: 0 20px;
		}

		@include device-content(sp) {
			padding: 0 10px;
		}

		li {
			margin-left: -1px;
			position: relative;

			@include device-content(pc) {
				width: 275px;
			}

			@media only screen and (max-width: 1099px) {
				width: 25%;
			}

			@include device-content(sp) {
				width: 50%;
			}

			&.ui-state-active {
				a {
					color: #fff;
					background: #0f62b6;
				}

				&:after {
					display: inline-block;
					position: absolute;
					content: "";
					width: 0;
					height: 0;
					left: 50%;
					transform: translate(-50%, 0);
					border-style: solid;
					border-width: 12px 13px 0 13px;
					border-color: #0f62b6 transparent transparent transparent;
				}
			}

			&:first-child {
				margin-left: 0;
			}

			a {
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				color: #0f62b6;
				background: #fff;
				line-height: 1.25;
				border: 1px solid #0f62b6;
				border-bottom: none;

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}

				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 18px);
					padding: 0 10px;
					height: 60px;
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 18px * .6);
					padding: 0 5px;
					height: 30px;
				}

				small {
					@include device-content(pc) {
						@include device-prop-rem(font-size, pc, 14px);
					}

					@include device-content(sp) {
						display: none;
					}
				}
			}
		}
	}
}

.product-case-example {
	background: #eee;

	@include device-content(pc) {
		padding: 50px 0 70px;
	}

	@include device-content(sp) {
		padding: 25px 0 30px;
	}

	.product-case-example__text {
		line-height: 1.75;
	}
}

.product-simple-spec {
	@include device-content(pc) {
		padding: 50px 0 70px;
	}

	@include device-content(sp) {
		padding: 25px 0 30px;
	}

	.product-simple-spec__text {
		line-height: 1.75;
	}

	.product-simple-spec__table {
		line-height: 1.75;
		width: 100%;

		@include device-content(sp) {
			display: block;
		}

		tbody {
			@include device-content(sp) {
				display: block;
			}
		}

		tr {
			@include device-content(sp) {
				display: block;
			}
		}

		th, td {
			@include device-content(sp) {
				display: block;
			}
		}

		th {
			text-align: left;
			width: 140px;
			padding-right: 10px;

			@include device-content(sp) {
				width: auto;
				font-weight: bold;
			}
		}
	}
}

.product-ba {
	@include device-content(pc) {
		padding: 90px 0 50px;
	}

	@include device-content(sp) {
		padding: 40px 0 25px;
	}
}

.product-ba-img {
	text-align: center;
	margin-top: 40px;

	img {
		max-width: 100%;
		height: auto;
	}
}

.product-ba-sec {
	position: relative;
	margin-top: 40px;

	+ .com-sec__title {
		margin-top: 150px;

		@include device-content(sp) {
			margin-top: 75px;
		}
	}

	&:first-child {
		margin-top: 0;
	}

	&.-before {
		padding-bottom: 60px;

		&:after {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			content: "";
			width: 41px;
			height: 21px;
			background: url("../../img/product/icn_triangle_down.png") no-repeat left top;
		}
	}

	.product-ba-sec__title {
		position: relative;
		text-align: center;
		line-height: 1.25;
		overflow: hidden;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 30px);
			margin: 20px 0;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 30px * .6);
			margin: 10px 0;
			padding: 0 10px;
		}

		span {
			display: inline-block;
			position: relative;
			color: #0f63b7;
			font-weight: bold;

			&:before,
			&:after {
				height: 3px;
				width: 100vw;
				background: #0f62b8;
				position: absolute;
				content: "";
				top: 50%;
			}

			&:before {
				left: calc(100% + 15px);
			}

			&:after {
				right: calc(100% + 15px);
			}
		}
	}
}

.product-ba-note {
	line-height: 1.75;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 16px);
		margin-bottom: 40px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 16px * .6);
		margin-bottom: 20px;
	}
}

.product-ba-note02 {
	line-height: 1.75;
	font-weight: bold;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 20px);
		margin-bottom: 25px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 20px * .6);
		margin-bottom: 12px;
	}
}

.product-ba-note03 {
	line-height: 1.75;
	font-weight: bold;
	color: #0664b9;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 16px);
		margin-bottom: 40px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 16px * .6);
		margin-bottom: 20px;
	}
}

.product-ba-desc {
	.product-ba-desc__title {
		font-weight: bold;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 24px);
			margin-bottom: 10px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 24px * .6);
			margin-bottom: 5px;
		}
	}

	.product-ba-desc__text {
		line-height: 1.5;
		border-bottom: 1px solid #ccc;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			padding: 16px 0;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			padding: 8px 0;
		}
	}

}

.product-ba-desc02 {
	.product-ba-desc02__title {
		font-weight: bold;
		color: #0664b9;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}

	.product-ba-desc02__text {
		line-height: 1.5;
		border-bottom: 1px solid #ccc;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 16px);
			padding: 16px 0;
			margin-bottom: 16px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 16px * .6);
			padding: 8px 0;
			margin-bottom: 8px;
		}
	}

}

.product-case {
	@include device-content(pc) {
		padding: 90px 0 20px;
	}

	@include device-content(sp) {
		padding: 40px 0 10px;
	}

	.product-sec__title {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
			margin-bottom: 70px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 26px * .6);
			margin-bottom: 35px;
		}

		&:after {
			display: none;
		}
	}

	.product-case__item {
		@include device-content(pc) {
			display: flex;
			margin-bottom: 60px;
		}

		@include device-content(sp) {
			margin-bottom: 30px;
		}
	}

	.product-case__img {
		@include device-content(pc) {
			width: 300px;
			margin-right: auto;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.product-case__data {
		@include device-content(pc) {
			width: 760px;
		}

		@include device-content(sp) {
			margin-top: 15px;
		}
	}

	.product-case__title {
		color: #0f63b8;
		font-weight: bold;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 24px);
			margin-bottom: 20px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 24px * .6);
			margin-bottom: 10px;
		}
	}

	.product-case__text {
		padding-bottom: 0.1em;
		background-color: #fff;
		background-image: linear-gradient(to bottom, #ccc 1.1px, transparent 1px);

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			background-size: 1px 2.6em;
			line-height: 2.6;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			background-size: 2px 2.6em;
			line-height: 2.6;
		}
	}
}

.product-faq {
	@include device-content(pc) {
		padding: 90px 0 20px;
	}

	@include device-content(sp) {
		padding: 40px 0 10px;
	}

	.product-sec__title {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
			margin-bottom: 70px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 26px * .6);
			margin-bottom: 30px;
		}

		&:after {
			display: none;
		}
	}

	.product-spec-sec {
		margin: 0;

		table {
			margin: 0;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	dl {
		dt {
			font-weight: bold;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 20px);
				margin-bottom: 10px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 20px * .6);
				margin-bottom: 5px;
			}
		}

		dd {
			margin-bottom: 30px;

			@include device-content(sp) {
				margin-bottom: 15px;
			}
		}
	}
}

// ============================================================
//  recruit
// ============================================================

.page-recruit {
	.com-pre-text {
		text-align: center;
	}
}

// ============================================================
//  recruit-message
// ============================================================

.recruit-message {
	.cnt-flex {
		@include device-content(sp) {
			display: block;
		}
	}
}

.recruit-message__image {
	@include device-content(pc) {
		width: 32%;
		margin-right: auto;
	}

	@include device-content(sp) {
		margin-bottom: 1.5em;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.recruit-message__text {
	line-height: 1.75;

	@include device-content(pc) {
		width: 62%;
		margin-right: auto;
		@include device-prop-rem(font-size, pc, 16px);
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 16px * .6);
	}

	p {
		margin-bottom: 1em;
	}
}

.recruit-require {
	margin-top: 100px;
}

.recruit-require-list {
	list-style: decimal;
	padding-left: 1.25em;

	.recruit-require-list__item {
		color: #0f63b9;
		line-height: 1.5;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
			margin-bottom: 10px;
		}
	}
}

.product-simple-spec-sec {
	margin-top: 50px;

	@include device-content(sp) {
		margin-top: 25px;
	}

	&:first-child {
		margin-top: 0;
	}

	.product-simple-spec-sec__title {
		color: #0f63b9;
		font-weight: bold;
		line-height: 1.5;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			margin-bottom: 10px;
		}
	}

	.product-simple-spec-sec__body {
		line-height: 1.75;
	}
}

// ============================================================
//  recruit-faq
// ============================================================

.page-recruit-faq {
	.com-faq-list {
		.com-faq-list__item {
			.com-faq-list__question {
				color: #333;

				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 20px);
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 20px * .6);
				}

				&:before {
					color: #1560b4;

					@include device-content(pc) {
						@include device-prop-rem(font-size, pc, 22px);
					}

					@include device-content(sp) {
						@include device-prop-rem(font-size, sp, 22px * .6);
					}
				}
			}
		}
	}
}

// ============================================================
//  recruit-training
// ============================================================

.recruit-training {
	.bg-gray {
		background: #f4f4f4;

		@include device-content(pc) {
			padding: 45px 0 115px;
		}

		@include device-content(sp) {
			padding: 20px 0 40px;
		}
	}

	.com-full-image {
		margin-bottom: 80px;

		@include device-content(sp) {
			margin-bottom: 40px;
		}
	}
}

.recruit-training-table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #bbb;
	background-color: #fff;

	.recruit-training-table__col-1 {
		width: 10%;
	}

	.recruit-training-table__col-2 {
		width: 20%;
	}

	.recruit-training-table__col-3 {
		width: 40%;
	}

	.recruit-training-table__col-4 {
		width: 30%;
	}

	th, td {
		vertical-align: middle;
		border: 1px solid #bbb;

		@include device-content(pc) {
			line-height: 1.75;
			padding: 10px 25px;
		}

		@include device-content(sp) {
			line-height: 1.5;
			padding: 5px 12px;
		}

		&.text-center {
			text-align: center;
		}
	}

	th {
		text-align: center;
		color: #0f63b7;
		font-weight: bold;
	}

	tr {
		&.color-1 {
			td {
				background-color: #eee;
			}
		}

		&.color-2 {
			td {
				background-color: #e8e5f6;
			}
		}

		&.color-3 {
			td {
				background-color: #e2f6eb;
			}
		}

		&.color-4 {
			td {
				background-color: #fbeace;
			}
		}

		td {
			&.bg-white {
				background-color: #fff;
			}
		}
	}
}

.recruit-training-point {
	overflow: hidden;

	@include device-content(pc) {
		margin-top: 100px;
	}

	@include device-content(sp) {
		margin-top: 30px;
	}

	&:first-child {
		@include device-content(pc) {
			margin-top: 60px;
		}
	}

	.recruit-training-point__title {
		position: relative;
		text-align: center;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
			margin-bottom: 35px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
			margin-bottom: 10px;
		}

		span {
			display: inline-block;
			position: relative;
			color: #0f63b7;
			font-weight: bold;

			&:before,
			&:after {
				height: 1px;
				width: 100vw;
				background: #ccc;
				position: absolute;
				content: "";
				top: 50%;
			}

			&:before {
				left: calc(100% + 15px);
			}

			&:after {
				right: calc(100% + 15px);
			}
		}
	}

	.recruit-training-point__image {
		@include device-content(pc) {
			float: right;
			margin-left: 40px;
		}

		@include device-content(sp) {
			margin-bottom: 20px;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.recruit-training-point__text {
		line-height: 1.75;
	}
}

// ============================================================
//  policy
// ============================================================

.policy {
	@include device-content(pc) {
		margin-top: 60px;
	}

	@include device-content(sp) {
		margin-top: 30px;
	}

	.policy-text {
		line-height: 1.75;
	}

	.policy-list {
		list-style: disc;
		line-height: 1.75;
		padding-left: 1.5em;

		@include device-content(pc) {
			margin-top: 10px;
			margin-bottom: 20px;
		}

		@include device-content(sp) {
			margin-top: 5px;
			margin-bottom: 10px;
		}

		&:first-child {
			margin-top: 0;
		}

		.policy-list__item {
			color: #0f63b9;
		}
	}
}

// ============================================================
//  privacy
// ============================================================

.privacy-list {
	list-style: decimal;
	line-height: 1.75;
	padding-left: 1.5em;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 22px);
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 22px * .6);
	}

	&:first-child {
		margin-top: 0;
	}

	.privacy-list__item {
		color: #0f63b9;

		@include device-content(pc) {
			margin-bottom: 30px;
		}

		@include device-content(sp) {
			margin-bottom: 15px;
		}
	}
}

// ============================================================
//  page-company-message
// ============================================================

.page-company-message {
	.footer-sub-menu {
		margin-top: 60px;

		@include device-content(sp) {
			margin-top: 30px;
		}
	}
}

.company-message {
	.company-message__image {
		@include device-content(pc) {
			width: 32%;
			margin-right: 20px;
			margin-bottom: 30px;
			float: left;
		}

		@include device-content(sp) {
			margin-bottom: 1.5em;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.company-message__text {
		line-height: 1.75;

		p {
			margin-bottom: 1em;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 16px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 16px * .6);
			}
		}
	}
}

// ============================================================
//  page-company-history
// ============================================================

.page-company-history {
	.com-full-image {
		margin-bottom: 30px;
	}

	.com-text {
		margin-bottom: 30px;
	}
}

.company-history-table {
	width: 100%;

	th, td {
		padding: 15px 0;
		line-height: 1.75;
		border-bottom: 1px dotted #cbcbcb;

		@include device-content(sp) {
			padding: 8px 0;
		}
	}

	th {
		width: 150px;
		text-align: left;

		@include device-content(sp) {
			width: 80px;
		}
	}
}

// ============================================================
//  company-about
// ============================================================

.company-about {
	.com-full-image {
		margin-bottom: 50px;

		@include device-content(sp) {
			margin-bottom: 25px;
		}
	}
}

.company-about-table {
	width: 100%;

	th, td {
		padding: 15px 0;
		line-height: 1.75;

		@include device-content(sp) {
			padding: 8px 0;
		}
	}

	th {
		width: 150px;
		text-align: left;

		@include device-content(sp) {
			width: 80px;
		}
	}
}

// ============================================================
//  company-policy
// ============================================================

.page-company-policy {
	.com-sec {
		margin-top: 80px;

		@include device-content(sp) {
			margin-top: 40px;
		}
	}
}

.company-policy-image {

	img {
		max-width: 100%;
		height: auto;
	}
}

.company-policy-list {
	.company-policy-list__item {
		line-height: 1.75;
		margin-top: 10px;
		color: #0f63b8;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
		}

		&:first-child {
			margin-top: 0;
		}
	}
}

// ============================================================
//  company-access
// ============================================================

.page-company-access {
	.com-sec {
		margin-top: 60px;

		@include device-content(sp) {
			margin-top: 30px;
		}
	}
}

.company-access-world {
	@include clearfix;
	background: url("../../img/company/access/bg_world_branch.png") no-repeat center;

	@include device-content(sp) {
		background-size: contain;
	}

	.company-access-world__title {
		line-height: 1.75;
		margin-bottom: 5px;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}

	.company-access-world__desc {
		line-height: 1.75;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 18px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 18px * .6);
		}
	}

	.company-access-world__item {
		box-sizing: border-box;
		margin-top: 30px;

		&:first-child {
			margin-top: 0;
		}

		@include device-content(pc) {
			float: left;
			margin-left: 50px;
			margin-right: -50px;
			width: 50%;
			padding-right: 10px;
			background: no-repeat center bottom / contain;
		}

		@include device-content(sp) {
			background: no-repeat center top / contain;
		}

		.company-access-world__image {
			text-align: center;

			img {
				max-width: 70%;
				height: auto;
			}
		}

		&.-japan {
			@include device-content(pc) {
				background-image: url("../../img/company/access/img_map_japan.png");
				margin-bottom: 30px;
				height: 318px;
			}

			.company-access-world__desc {
				@include device-content(sp) {
					position: absolute;
					left: 1em;
					top: 3em;
				}
			}
		}

		&.-euro {
			@include device-content(pc) {
				background-image: url("../../img/company/access/img_map_euro.png");
				float: right;
				padding-right: 0;
				padding-left: 10px;
				height: 496px;
			}
		}

		&.-indonesia {
			@include device-content(pc) {
				height: 222px;
				background-image: url("../../img/company/access/img_map_indonesia.png");
				background-position: left bottom;
			}
		}
	}
}

.company-access {
	.company-access__item {
		line-height: 1.75;
		border-bottom: 1px solid #ccc;
		padding: 20px 0;
		margin-top: 15px;

		@include device-content(sp) {
			padding: 10px 0;
			margin-top: 10px;
		}

		&:first-child {
			margin-top: 0;
			padding-top: 0;
		}

		.company-access__title {
			color: #0f63b8;

			@include device-content(pc) {
				margin-bottom: 10px;
				@include device-prop-rem(font-size, pc, 20px);
			}

			@include device-content(sp) {
				margin-bottom: 5px;
				@include device-prop-rem(font-size, sp, 20px * .6);
			}
		}

		.company-access__desc {
			margin-bottom: 10px;
		}

		.company-access__gmap {
			margin-bottom: 10px;

			@include device-content(sp) {
				margin-bottom: 6px;
			}

			iframe {
				width: 100%;
				height: 400px;

				@include device-content(sp) {
					height: 250px;
				}
			}
		}
	}
}

// ============================================================
//  business
// ============================================================

.page-business {
	.com-sec {
		margin-top: 60px;

		@include device-content(sp) {
			margin-top: 30px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	.bg-gray {
		margin-top: 40px;
		padding: 45px 0;
		background: #f4f4f4;

		@include device-content(sp) {
			margin-top: 20px;
			padding: 20px 0;
		}
	}
}

.business-main {
	.com-sec__title {
		line-height: 1.45;
	}

	.business-main__image {
		position: relative;
		text-align: center;
		padding: 30px 0;
		margin-bottom: 30px;
		background: url("../../img/business/bg_main.jpg") no-repeat center / cover;

		@include device-content(sp) {
			padding: 20px 10px;
			margin-bottom: 20px;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.business-excerpt {
	margin-top: 80px;

	@include device-content(sp) {
		margin-top: 40px;
	}

	.business-excerpt__item {
		padding: 30px 0;
		border-bottom: 1px solid #0f62b6;

		@include device-content(sp) {
			padding: 15px 0;
		}

		&:first-child {
			padding-top: 0;
		}

		a {
			text-decoration: none;
			position: relative;

			@include device-content(pc) {
				display: flex;
				justify-content: center;
			}

			&:after {
				position: absolute;
				display: block;
				content: "";
				width: 18px;
				height: 30px;
				background: url("../../img/business/icn_arrow.png") no-repeat center;
				right: 20px;
				top: 50%;
				transform: translate(0, -50%);
			}

			&:hover {
				opacity: 0.6;
			}
		}
	}

	.business-excerpt__title {
		position: absolute;
		text-align: center;
		line-height: 1.25;
		left: 0;
		top: 0;
		background: #0f63b6;
		color: #fff;
		width: 100%;
		padding: 18px 0;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 22px * .6);
		}
	}

	.business-excerpt__image {
		width: 32.5%;
		position: relative;
		margin-right: auto;

		@include device-content(sp) {
			width: 100%;
			box-sizing: border-box;
			margin-bottom: 10px;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.business-excerpt__text {
		width: 65.5%;
		line-height: 1.75;
		box-sizing: border-box;
		padding-right: 100px;

		@include device-content(sp) {
			width: 100%;
			box-sizing: border-box;
			padding-right: 0;
		}
	}
}

// ============================================================
//  business-detail
// ============================================================

.page-business-detail {
	.bg-gray {
		background: #f4f4f4;
		padding: 50px 0;
		margin-top: 80px;

		@include device-content(sp) {
			padding: 25px 0;
			margin-top: 40px;
		}
	}
}

.sec-business-detail-outline {
	.com-full-image {
		margin-bottom: 25px;

		@include device-content(sp) {
			margin-bottom: 15px;
		}
	}

	.com-text {
		margin-bottom: 40px;

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}
}

.business-product-row {
	margin-bottom: 55px;

	@include device-content(sp) {
		margin-bottom: 30px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.-col-3 {
		@include device-content(pc) {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin-left: -10px;
			margin-right: -10px;
		}

		.business-product-cell {
			@include device-content(pc) {
				width: 33.3333%;
				box-sizing: border-box;
				padding: 0 10px;
			}

			@include device-content(sp) {
				margin-bottom: 15px;
			}
		}
	}
}

.business-product-cell {
	.business-product-cell__image {
		margin-bottom: 10px;

		img {
			width: 100%;
			height: auto;
		}
	}

	.business-product-cell__name {
		text-align: center;
		line-height: 1.25;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 18px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 18px * .6);
		}
	}
}

.business-product-feature {
	.business-product-feature__title {
		color: #0f63b6;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			margin-bottom: 10px;
			padding-bottom: 10px;
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}

	.business-product-feature__desc {
		line-height: 1.75;
		margin-bottom: 40px;

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}
}

.sec-business-detail-field {
	.com-text {
		margin-bottom: 40px;

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}
}

.business-detail-field {
	@include device-content(pc) {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
	}

	.business-detail-field__item {
		box-sizing: border-box;

		@include device-content(pc) {
			padding: 0 15px;
			width: 50%;
			margin-bottom: 40px;
		}

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}

	.business-detail-field__title {
		margin-bottom: 10px;
		color: #0f63b6;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}

	.business-detail-field__image {
		img {
			@include device-content(pc) {
				max-height: 100%;
				max-width: 100%;
				height: auto;
			}

			@include device-content(sp) {
				width: 100%;
				height: auto;
			}
		}

		margin-bottom: 10px;
	}

	.business-detail-field__desc {
		line-height: 1.5;
	}
}

.business-contact {
	margin-top: 40px;

	@include device-content(sp) {
		margin-top: 20px;
	}

	&.-border-blue {
		.business-contact__item {
			border-bottom: 1px solid #0f63b6;
		}
	}

	.business-contact__item {
		@include clearfix;
		color: #0f63b6;
		border-bottom: 1px solid #ccc;
		box-sizing: border-box;
		padding: 20px 10px;

		@include device-content(sp) {
			padding: 10px 10px;
		}
	}

	.business-contact__title {
		font-weight: bold;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			margin-bottom: 10px;
		}
	}

	.business-contact__addr {
		line-height: 1.25;

		@include device-content(pc) {
			float: left;
		}

		@include device-content(sp) {
			margin-bottom: 5px;
		}
	}

	.business-contact__numbers {
		line-height: 1.25;

		@include device-content(pc) {
			float: right;
		}
	}
}

// ============================================================
//  business-strength
// ============================================================

.page-business-strength {
	.com-action__btn {
		width: 360px;

		@include device-content(sp) {
			width: 100%;
		}
	}
}

// ============================================================
//  business-feature
// ============================================================

.page-business-feature {
	.bg-blue {
		background: #0f62b8;
		margin-top: 60px;
		padding: 60px 0;

		@include device-content(sp) {
			margin-top: 30px;
			padding: 30px 0;
		}

		.cnt-sub {
			background: url("../../img/business/feature/bg_euro.png") no-repeat center bottom / contain;

			@include device-content(sp) {
				background-position: center;
			}
		}
	}
}

.business-feature {
	.business-feature__title {
		text-align: center;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 30px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 16px);
		}
	}

	.business-feature__number-1 {
		color: #b1861b;
		text-align: center;
		position: relative;
		margin-top: 10px;
		font-weight: bold;

		&:before {
			display: inline-block;
			vertical-align: middle;
			content: "";
			width: 93px;
			height: 119px;
			margin-right: 10px;
			position: relative;
			top: -5px;
			background: url("../../img/business/feature/img_medal.png") no-repeat center / 93px 119px;

			@include device-content(sp) {
				width: 46px;
				height: 59px;
				background-size: 46px auto;
			}
		}

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 60px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px);
		}
	}

	.business-feature__img {
		text-align: center;
		margin-top: -20px;
		min-height: 520px;
		box-sizing: border-box;
		padding-bottom: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url("../../img/business/feature/bg_feature.png") no-repeat center;

		img {
			max-width: 100%;
			height: auto;
		}

		@include device-content(sp) {
			min-height: 0;
			margin-top: 10px;
			padding: 0 30px 30px;
			background-size: contain;
		}
	}

	.com-action {
		margin-top: -20px;

		@include device-content(sp) {
			margin-top: 0;
		}

		.com-action__btn {
			width: 360px;

			@include device-content(sp) {
				width: 100%;
			}
		}
	}
}

.euro-company {
	.euro-company__title {
		color: #fff;
		font-weight: bold;
		text-align: center;

		@include device-content(pc) {
			margin-bottom: 80px;
			@include device-prop-rem(font-size, pc, 60px);
		}

		@include device-content(sp) {
			margin-bottom: 30px;
			@include device-prop-rem(font-size, sp, 20px);
		}

		span {
			color: #fd8225;
			font-weight: bold;
		}
	}
}

.euro-company-country {
	text-align: center;
	letter-spacing: -0.4em;

	.euro-company-country__item {
		color: #fff;
		display: inline-block;
		vertical-align: top;
		letter-spacing: normal;
		margin: 0 15px 50px;

		@include device-content(sp) {
			margin: 0 10px 30px;
		}

		&:nth-child(1) {
			width: 210px;
			margin-right: 20px;

			@include device-content(sp) {
				width: auto;
				margin-right: 10px;
			}
		}

		&:nth-child(n+2):nth-child(-n+5) {
			margin: 0 20px 50px;

			@include device-content(sp) {
				margin: 0 10px 30px;
			}

			.euro-company-country__name {
				margin-bottom: 30px;

				@include device-content(sp) {
					margin-bottom: 8px;
				}
			}
		}
	}

	.euro-company-country__number {
		font-weight: bold;
		margin-bottom: 8px;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 40px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 40px * .6);
		}

		span {
			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 22px);
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 22px * .6);
			}
		}
	}

	.euro-company-country__name {
		font-weight: bold;

		@include device-content(pc) {
			margin-bottom: 15px;
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			margin-bottom: 8px;
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}
}

.euro-company-country-ship {
	letter-spacing: -0.4em;

	.euro-company-country-ship__item {
		letter-spacing: normal;
		display: inline-block;
		width: 12px;
		height: 10px;
		margin: 0 3px;
		background: url("../../img/business/feature/icn_ship.png") no-repeat left top / 12px auto;

		@include device-content(sp) {
			width: 6px;
			height: 5px;
			margin: 0 2px;
			background-size: 6px auto;
		}

		&.-l1 {
			width: 28px;
			height: 22px;
			margin: 0 3px;
			background-size: 28px auto;

			@include device-content(sp) {
				width: 14px;
				height: 11px;
				margin: 0 2px;
				background-size: 14px auto;
			}
		}

		&.-l2 {
			width: 63px;
			height: 50px;
			margin: 0 4px 5px;
			background-size: 63px auto;

			@include device-content(sp) {
				width: 31px;
				height: 25px;
				margin: 0 2px 3px;
				background-size: 31px auto;
			}
		}

		&.-l3 {
			width: 103px;
			height: 81px;
			margin: 0 5px 10px;
			background-size: 103px auto;

			@include device-content(sp) {
				width: 51px;
				height: 40px;
				margin: 0 3px 5px;
				background-size: 51px auto;
			}
		}
	}
}

.page-product-support {
	.com-sec {
		margin-bottom: 50px;

		@include device-content(sp) {
			margin-bottom: 25px;
		}
	}

	.com-full-image {
		margin-bottom: 20px;

		@include device-content(sp) {
			margin-bottom: 10px;
		}
	}

	.bg-gray {
		background: #f5f5f5;
		padding: 50px 0;
		margin: 50px 0;

		@include device-content(sp) {
			padding: 25px 0;
			margin: 25px 0;
		}

		.com-sec {
			margin-bottom: 0;
		}
	}
}

.sec-product-support-1 {
	.com-text {
		margin-bottom: 50px;

		@include device-content(sp) {
			margin-bottom: 25px;
		}
	}
}

.sec-product-support-2 {
	.com-text {
		margin-bottom: 50px;

		@include device-content(sp) {
			margin-bottom: 25px;
		}
	}
}

.sec-product-support-3 {
	.com-text {
		margin-bottom: 15px;
	}

	.com-strong-list {
		margin-bottom: 20px;

		@include device-content(sp) {
			margin-bottom: 10px;
		}
	}
}

.sec-product-support-4 {
	.com-action {
		margin-top: 40px;

		@include device-content(sp) {
			margin-top: 20px;
		}

		.com-action__btn {
			width: 500px;

			@include device-content(sp) {
				width: 100%;
			}

		}
	}
}

.recruit-requirements {
	margin-top: 100px;

	@include device-content(sp) {
		margin-top: 50px;
	}
}

.recruit-requirements-table {
	width: 100%;

	th, td {
		padding: 15px 0;
		line-height: 1.75;
		border-bottom: 1px solid #cbcbcb;

		@include device-content(sp) {
			padding: 8px 0;
		}
	}

	th {
		width: 150px;
		text-align: left;

		@include device-content(sp) {
			width: 80px;
		}
	}
}

.recruit-process {
	margin-top: 80px;

	@include device-content(sp) {
		margin-top: 40px;
	}

	.recruit-process__sub-title {
		color: #05458a;
		text-align: center;
		margin-bottom: 30px;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			margin-bottom: 15px;
			@include device-prop-rem(font-size, sp, 22px * .6);
		}
	}

	.recruit-process__link {
		margin-top: 50px;
		text-align: center;

		@include device-content(sp) {
			margin-top: 25px;
		}
	}
}

.recruit-process-flow {
	width: 700px;
	margin: 0 auto;
	line-height: 1.5;

	@include device-content(sp) {
		width: auto;
	}

	.recruit-process-flow__title {
		color: #fff;
		margin-bottom: 10px;
		text-align: center;
		font-weight: bold;
		background: #05458a;
		padding: 5px 0;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			margin-bottom: 5px;
			@include device-prop-rem(font-size, sp, 22px * .6);
		}
	}

	.recruit-process-flow__desc {
		padding-bottom: 38px;
		text-align: center;
		margin-bottom: 10px;
		background: url("../../img/recruit/new_graduate/icn_arrow_down.png") no-repeat center bottom;

		@include device-content(sp) {
			margin-bottom: 10px;
			padding-bottom: 19px;
			background-size: 23px auto;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			background: none;
		}
	}
}

.free-word-list {
	.free-word-list__item {
		border-bottom: 1px solid #dedede;

		@include device-content(pc) {
			padding: 30px 0;
		}

		@include device-content(sp) {
			padding: 15px 0;
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}

		&:first-child {
			padding-top: 0;
		}

		.free-word-list__title {
			color: #0f62b8;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 26px);
				font-weight: bold;
				margin-bottom: 20px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 26px * .6);
				margin-bottom: 10px;
			}
		}

		.free-word-list__date {
			@include device-content(pc) {
				margin-bottom: 15px;
			}

			@include device-content(sp) {
				margin-bottom: 7px;
			}
		}

		.free-word-list__data {
			line-height: 1.5;
		}
	}
}

.recruit-career-sec {
	@include device-content(pc) {
		margin-bottom: 60px;
	}

	@include device-content(sp) {
		margin-bottom: 30px;
	}

	.com-sec__title {
		line-height: 1.5;
	}
}

.recruit-career-list {
	@include device-content(pc) {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
	}

	.recruit-career-list__item {
		@include device-content(pc) {
			min-width: 220px;
			margin-left: -1px;
		}

		@include device-content(sp) {
			margin-top: -1px;
		}

		border: 1px solid #bbb;

		a {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #0663ba;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 18px);
				min-height: 60px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 18px * .6);
				padding: 10px;
			}

			&:after {
				display: block;
				width: 12px;
				height: 19px;
				content: "";
				background: url("../../img/common/icn_arrow_right_08.png") no-repeat left top;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);

				@include device-content(sp) {
					right: 5px;
					width: 6px;
					height: 9px;
					background-size: 6px auto;
				}
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			&.active {
				background: #0663ba;
				color: #fff;

				&:after {
					display: none;
				}
			}

			&.disabled {
				color: #bbb;
				pointer-events: none;

				&:after {
					display: none;
				}
			}
		}
	}
}

.recruit-career-cnt {
	@include device-content(pc) {
		display: flex;
	}
}

.recruit-career-map {
	@include device-content(pc) {
		width: 520px;
		margin-right: auto;
	}

	@include device-content(sp) {
		margin-bottom: 20px;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

.recruit-career-sub-list {
	@include device-content(pc) {
		width: 520px;
	}

	.recruit-career-sub-list__item {
		@include device-content(pc) {
			padding: 20px 0;
		}

		@include device-content(sp) {
			padding: 10px 0;
		}

		border-bottom: 1px solid #0663ba;

		&:first-child {
			padding-top: 0;
		}

		a {
			position: relative;
			display: block;

			&:after {
				display: block;
				width: 12px;
				height: 19px;
				content: "";
				background: url("../../img/common/icn_arrow_right_08.png") no-repeat left top;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);

				@include device-content(sp) {
					right: 10px;
					width: 6px;
					height: 9px;
					background-size: 6px auto;
				}
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			&.disabled {
				pointer-events: none;

				&:after {
					display: none;
				}

				.recruit-career-sub-list__title {
					color: #bbb;
				}
			}
		}
	}

	.recruit-career-sub-list__title {
		line-height: 1.25;
		color: #0663ba;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 10px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			margin-bottom: 5px;
		}
	}

	.recruit-career-sub-list__desc {
		line-height: 1.5;
	}
}

.recruit-career-table {
	width: 100%;

	@include device-content(sp) {
		display: block;
	}

	tbody {
		@include device-content(sp) {
			display: block;
		}
	}

	tr {
		@include device-content(sp) {
			display: block;
		}
	}

	td, th {
		line-height: 1.6;

		@include device-content(pc) {
			padding: 12px 0;
			border-bottom: 1px solid #ccc;
		}

		@include device-content(sp) {
			display: block;
		}
	}

	th {
		color: #0664ba;
		text-align: left;

		@include device-content(pc) {
			width: 185px;
		}
	}

	td {
		@include device-content(sp) {
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid #ccc;
		}
	}
}

.site-map__row {
	@include device-content(pc) {
		display: flex;
		align-items: flex-start;
	}

	.site-map__col {
		@include device-content(pc) {
			width: calc(50% - 23px);
		}

		&:first-child {
			@include device-content(pc) {
				margin-right: auto;
			}
		}
	}
}

.site-map-list {
	.site-map-list__item {
		padding-bottom: 25px;
		margin-bottom: 25px;
		border-bottom: 1px solid #ccc;

		@include device-content(sp) {
			padding-bottom: 15px;
			margin-bottom: 15px;
		}

	}

	.site-map-list__title {
		line-height: 1.5;
		color: #0664b8;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 26px * .6);
			margin-bottom: 5px;
		}
	}
}

.site-map-link-list {
	li {
		line-height: 1.5;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			margin-bottom: 5px;
		}
	}
}

.sec-product-order-1 {
	.com-text {
		margin-bottom: 40px;

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}
}

.sec-product-order-2 {
	.com-text {
		margin-bottom: 60px;

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}
}

.order-list {
	.order-list__item {
		@include device-content(pc) {
			display: flex;
			align-items: flex-start;
			margin-bottom: 20px;
		}

		@include device-content(sp) {
			margin-bottom: 20px;
		}
	}

	.order-list__img {
		@include device-content(pc) {
			width: calc(50% - 15px);
			margin-right: auto;
		}

		@include device-content(sp) {
			margin-bottom: 10px;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.order-list__data {
		line-height: 1.5;

		@include device-content(pc) {
			width: calc(50% - 15px);
		}
	}

	.order-list__title {
		color: #0664b9;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 10px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			margin-bottom: 5px;
		}
	}

	.order-list__sub-title {
		border-bottom: 1px solid #ccc;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 18px);
			margin-bottom: 8px;
			padding-bottom: 8px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 18px * .6);
			margin-bottom: 4px;
			padding-bottom: 4px;
		}
	}

	.order-list__desc {
		@include device-content(pc) {
			margin-bottom: 20px;
		}

		@include device-content(sp) {
			margin-bottom: 10px;
		}
	}
}