// ============================================================
//  sec
// ============================================================

.sec {
	.sec-header {
		text-align: center;
	}

	.sec-title {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 30px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 15px);
		}
	}

	.sec-title__en {
		font-weight: bold;
		color: #0f63b8;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 15px);
			margin-top: 12px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 9px);
			margin-top: 8px;
		}
	}

	.sec-title__link {
		@include device-content(pc) {
			margin-top: 14px;
		}

		@include device-content(sp) {
			margin-top: 6px;
		}

		a {
			display: inline-block;
			position: relative;
			color: #1e63b8;

			@include device-content(pc) {
				padding-right: 24px;
			}

			@include device-content(sp) {
				@include device-prop-rem(font-size, sp, 9px);
				padding-right: 16px;
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			&:after {
				content: "";
				background: url("../../img/common/icn_arrow_right_03.png") no-repeat left top;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
				position: absolute;
				display: block;

				@include device-content(pc) {
					width: 15px;
					height: 14px;
				}

				@include device-content(sp) {
					background-size: 8px auto;
					width: 8px;
					height: 7px;
				}
			}
		}
	}
}

// ============================================================
//	com-sec
// ============================================================

.com-sec {
	.com-sec__title {
		text-align: center;
		position: relative;
		font-weight: bold;
		line-height: 1.25;

		@include device-content(pc) {
			margin-bottom: 60px;
			@include device-prop-rem(font-size, pc, 26px);
		}

		@include device-content(sp) {
			margin-bottom: 30px;
			@include device-prop-rem(font-size, sp, 26px * .6);
		}

		&:after {
			position: absolute;
			height: 1px;
			width: 58px;
			content: "";
			background: #0f62b8;
			left: 50%;
			bottom: -15px;
			transform: translate(-50%, 0);
		}
	}
}

// ============================================================
//	footer-suv-menu
// ============================================================

.footer-sub-menu {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #1560b4;

	@include device-content(pc) {
		margin-top: 190px;
		padding-bottom: 25px;
	}

	@include device-content(sp) {
		margin-top: 80px;
		padding-bottom: 15px;
	}

	+ .com-contact {
		@include device-content(pc) {
			margin-top: 40px;
		}

		@include device-content(sp) {
			margin-top: 20px;
		}
	}

	.footer-sub-menu__item {
		box-sizing: border-box;

		@include device-content(pc) {
			width: 280px;
			margin: 0 7px 15px 8px;
		}

		@include device-content(sp) {
			width: 50%;
			padding: 0 5px 10px 5px;
		}

		&.-active {
			a {
				background-color: #f5f5f5;
			}
		}

		a {
			display: flex;
			position: relative;
			text-align: center;
			align-items: center;
			justify-content: center;
			border: 1px solid #ccc;
			line-height: 1.333;

			@include device-content(pc) {
				@include device-prop-rem(font-size, pc, 18px);
				color: #0f63b6;
				height: 80px;
			}

			@include device-content(sp) {
				height: 40px;
				@include device-prop-rem(font-size, sp, 18px * .6);
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			&:after {
				content: "";
				background: url("../../img/recruit/icn_arrow_right.png") no-repeat left center / 12px 19px;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);

				@include device-content(pc) {
					width: 12px;
					height: 19px;
					right: 20px;
				}

				@include device-content(sp) {
					background-size: 6px 9px;
					width: 6px;
					height: 9px;
					right: 10px;
				}
			}
		}
	}
}

// ============================================================
//	page
// ============================================================

.page {
	.page__main-img {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		color: #fff;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-image: url("../../img/contact/img_main.jpg");

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 30px);
			height: 150px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 30px * .6);
			height: 75px;
		}
	}

	.page__title {
		position: relative;
		text-align: center;
		line-height: 1.25;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 30px);
			margin: 50px 0;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 30px * .6);
			margin: 25px 0;
			padding: 0 10px;
		}

		span {
			display: inline-block;
			position: relative;
			max-width: 1100px;
			color: #0f63b7;
			font-weight: bold;

			&:before,
			&:after {
				height: 3px;
				width: 100vw;
				background: #0f62b8;
				position: absolute;
				content: "";
				top: 50%;
			}

			&:before {
				left: calc(100% + 15px);
			}

			&:after {
				right: calc(100% + 15px);
			}
		}
	}
}

// ============================================================
//	breadcrumb
// ============================================================

.breadcrumb {
	background: #eee;
	display: flex;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 14px);
		padding: 15px 20px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 14px * .75);
		padding: 7px 10px;
	}

	.breadcrumb__item {
		&:after {
			content: ">";
			padding: 0 3px;
		}

		a {
			&:hover {
				text-decoration: underline;
			}
		}

		&:last-child {
			color: #0f63b6;

			&:after {
				display: none;
			}
		}
	}
}

// ============================================================
//	form-error
// ============================================================

.form-error {
	text-align: left;
	border: 2px solid #f00;
	background: #fff;
	box-sizing: border-box;
	line-height: 1.5;

	&.-contact {
		@include device-content(pc) {
			width: 600px;
		}
	}

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 14px);
		margin: 20px auto 30px;
		padding: 15px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 14px * 0.6);
		margin: 10px auto 15px;
		padding: 10px;
	}

	p {
		background: #f00;
		color: #fff !important;
		display: block;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 14px);
			margin: 0 0 20px;
			padding: 6px 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px * 0.6);
			margin: 0 0 10px;
			padding: 3px 10px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		padding-left: 1.5em;

		li {
			list-style: disc;
			color: #f00;
		}
	}
}

// ============================================================
//	action
// ============================================================

.action {
	text-align: center;

	.action__btn {
		display: inline-block;
		border: none;
		color: #fff;
		background: #0f62b8;
		position: relative;

		@include device-content(pc) {
			height: 80px;
			line-height: 80px;
		}

		@include device-content(sp) {
			height: 40px;
			line-height: 40px;
		}

		&:after {
			content: "";
			background: url("../../img/common/icn_btn_arrow_right.png") no-repeat left top;
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);

			@include device-content(pc) {
				width: 16px;
				right: 20px;
				height: 26px;
			}

			@include device-content(sp) {
				width: 8px;
				right: 10px;
				height: 13px;
				background-size: 8px auto;
			}
		}

		&:hover {
			opacity: 0.6;
		}

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px);
		}
	}
}

// ============================================================
//	contact-form
// ============================================================

.com-contact-form {
	@include device-content(pc) {
		width: 600px;
		margin: 0 auto;
	}

	.error {
		border-color: #d00;
		background: #fee;
	}

	.contact-form__title {
		@include device-content(pc) {
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			margin-bottom: 8px;
		}

		&:before {
			content: "●";
			color: #fc8335;
		}

		&.-no-mark {
			&:before {
				display: none;
			}
		}
	}

	.contact-form__field {
		@include device-content(pc) {
			margin-bottom: 40px;
		}

		@include device-content(sp) {
			margin-bottom: 20px;
		}

		+ .contact-form__field {
			@include device-content(pc) {
				margin-top: -10px;
			}

			@include device-content(sp) {
				margin-top: -5px;
			}
		}
	}
}

// ============================================================
//  form-components
// ============================================================

.form-radio {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&.-size-50p {
		.form-radio__item {
			@include device-content(pc) {
				min-width: 50%;
			}
		}
	}

	.form-radio__item {
		display: flex;
		align-items: center;

		> label {
			display: flex;
			align-items: center;
		}

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 18px);
			margin-bottom: 30px;
			min-width: 33.3333%;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px);
			margin-bottom: 15px;
			min-width: 50%;
		}

		.iradio {
			background: url("../../img/common/bg_radio.png") center left / 30px auto;

			@include device-content(pc) {
				width: 30px;
				height: 30px;
				margin-right: 10px;
			}

			@include device-content(sp) {
				width: 15px;
				height: 15px;
				margin-right: 5px;
				background-size: 15px auto;
			}

			&.checked {
				background-image: url("../../img/common/bg_radio_checked.png");
			}
		}

		.form-text {
			float: right;

			@include device-content(pc) {
				width: 450px;
				margin-left: 30px;
			}

			@include device-content(sp) {
				width: 200px;
				margin-left: 15px;
			}
		}
	}
}

//  form-row
// ------------------------------------------------------------

.form-row {
	@include device-content(pc) {
		margin-bottom: 20px;
	}

	@include device-content(sp) {
		margin-bottom: 10px;
	}
}

//  form-note
// ------------------------------------------------------------

.form-note {
	display: inline-block;

	&.-prefix {
		@include device-content(pc) {
			margin-right: 10px;
		}

		@include device-content(sp) {
			margin-right: 5px;
		}
	}

	&.-sep {
		@include device-content(pc) {
			margin: 0 10px;
		}

		@include device-content(sp) {
			margin: 0 5px;
		}
	}
}

//  form-text
// ------------------------------------------------------------

.form-text {
	border: 1px solid #aaa;
	box-sizing: border-box;
	padding: 0 10px;
	width: 100%;

	@include device-content(pc) {
		height: 35px;
	}

	@include device-content(sp) {
		height: 25px;
	}

	&.-large {
		@include device-content(pc) {
			height: 45px;
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			height: 30px;
			@include device-prop-rem(font-size, sp, 12px);
		}
	}

	@include placeholder-color(#ccc);

	&.-zip {
		@include device-content(pc) {
			width: 80px;
		}

		@include device-content(sp) {
			width: 50px;
		}
	}
}

//  form-select
// ------------------------------------------------------------

.form-select {
	width: 100%;
	box-sizing: border-box;

	select {
		width: 100%;
		box-sizing: border-box;
		position: relative;
		z-index: 2;
	}

	&.-large {
		select {
			@include device-content(pc) {
				height: 45px;
			}

			@include device-content(sp) {
				height: 30px;
			}
		}

		.customSelect {
			@include device-content(pc) {
				height: 45px;
				line-height: 44px;
				@include device-prop-rem(font-size, pc, 20px);
			}

			@include device-content(sp) {
				height: 30px;
				line-height: 29px;
				@include device-prop-rem(font-size, sp, 12px);
			}
		}
	}

	.customSelect {
		width: 100%;
		display: block;
		box-sizing: border-box;
		border: 1px solid #0f62b8;
		padding: 0 10px;
		position: relative;
		z-index: 1;

		@include device-content(pc) {
			height: 35px;
			line-height: 34px;
		}

		@include device-content(sp) {
			height: 25px;
			line-height: 24px;
		}

		&:after {
			content: "";
			display: block;
			background: url("../../img/common/icn_select_arrow_down.png") no-repeat left top / 18px 10px;
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);

			@include device-content(pc) {
				right: 15px;
				width: 18px;
				height: 10px;
			}

			@include device-content(sp) {
				right: 10px;
				width: 9px;
				height: 5px;
				background-size: 9px auto;
			}
		}
	}

	&.-center {
		.customSelectInner {
			text-align: center;
		}
	}

	&.-pref {
		@include device-content(pc) {
			width: 180px;
		}

		@include device-content(sp) {
			width: 100px;
		}
	}
}

//  form-text
// ------------------------------------------------------------

.form-text {
	border: 1px solid #aaa;
	box-sizing: border-box;
	padding: 0 10px;
	width: 100%;

	@include device-content(pc) {
		height: 35px;
	}

	@include device-content(sp) {
		height: 25px;
	}

	&.-large {
		@include device-content(pc) {
			height: 45px;
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			height: 30px;
			@include device-prop-rem(font-size, sp, 12px);
		}
	}

	@include placeholder-color(#ccc);

	&.-zip {
		@include device-content(pc) {
			width: 80px;
		}

		@include device-content(sp) {
			width: 50px;
		}
	}
}

//  form-textarea
// ------------------------------------------------------------

.form-textarea {
	border: 1px solid #aaa;
	box-sizing: border-box;
	padding: 10px;
	width: 100%;
}

// ============================================================
//  com-contact
// ============================================================

.com-contact {
	text-align: center;

	@include device-content(pc) {
		margin-top: 70px;
	}

	@include device-content(sp) {
		margin-top: 35px;
	}

	&.-border-top {
		border-top: 1px solid #2865b5;
		padding-top: 35px;
	}

	&.-border-bottom {
		border-bottom: 1px solid #2865b5;
		padding-bottom: 40px;
		margin-bottom: 30px;

		@include device-content(sp) {
			padding-bottom: 20px;
			margin-bottom: 15px;
		}
	}

	.com-contact__btn {
		display: inline-block;
		color: #fff;
		box-sizing: border-box;
		background: #0f62b6 url("../../img/common/icn_mail_02.png") no-repeat 30px center;

		@include device-content(pc) {
			width: 500px;
			padding: 30px;
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			width: 100%;
			padding: 20px;
			@include device-prop-rem(font-size, sp, 22px * .6);
			background-position: 15px center;
		}

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}
	}
}

// ============================================================
//  com-action
// ============================================================

.com-action {
	text-align: center;

	@include device-content(pc) {
		margin-top: 70px;
	}

	@include device-content(sp) {
		margin-top: 35px;
	}

	.com-action__btn {
		display: inline-block;
		color: #fff;
		box-sizing: border-box;
		background: #0f62b6;
		position: relative;

		&:after {
			width: 12px;
			position: absolute;
			height: 18px;
			content: "";
			right: 18px;
			top: 50%;
			transform: translateY(-50%);
			background: url("../../img/common/icn_arrow_right_07.png") no-repeat;
		}

		@include device-content(pc) {
			padding: 30px 50px;
			@include device-prop-rem(font-size, pc, 22px);
		}

		@include device-content(sp) {
			width: 100%;
			padding: 20px;
			@include device-prop-rem(font-size, sp, 22px * .6);
			background-position: 15px center;
		}

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}

		&.-inversion {
			background: #fff;
			color: #0f62b6;
			border: 1px solid #0f62b6;

			&:after {
				width: 12px;
				position: absolute;
				height: 18px;
				content: "";
				right: 18px;
				top: 50%;
				transform: translateY(-50%);
				background: url("../../img/common/icn_arrow_right_08.png") no-repeat;
			}
		}
	}
}

// ============================================================
//  pager
// ============================================================

.pager {
	display: flex;
	align-items: center;
	justify-content: center;

	@include device-content(pc) {
		margin-top: 50px;
	}

	@include device-content(sp) {
		margin-top: 25px;
	}

	.pager__item {
		margin: 0 2px;

		a {
			display: block;
			background: #cbcbcb;
			color: #fff;

			@include device-content(pc) {
				padding: 12px 20px;
			}

			@include device-content(sp) {
				padding: 6px 8px;
			}

			&:hover {
				text-decoration: none;
				background: #0f62b8;
			}
		}

		&.-current {
			a {
				background: #0f62b8;
			}
		}
	}
}

// ============================================================
//  com-faq
// ============================================================

.com-faq-title {
	overflow: hidden;
	position: relative;
	text-align: center;

	@include device-content(pc) {
		@include device-prop-rem(font-size, pc, 22px);
		margin-bottom: 35px;
	}

	@include device-content(sp) {
		@include device-prop-rem(font-size, sp, 22px * .6);
		margin-bottom: 10px;
	}

	span {
		display: inline-block;
		position: relative;
		color: #0f63b7;
		font-weight: bold;

		&:before,
		&:after {
			height: 1px;
			width: 100vw;
			background: #ccc;
			position: absolute;
			content: "";
			top: 50%;
		}

		&:before {
			left: calc(100% + 15px);
		}

		&:after {
			right: calc(100% + 15px);
		}
	}
}

.com-faq-list {
	counter-reset: faq;

	@include device-content(pc) {
		margin-bottom: 60px;
	}

	@include device-content(sp) {
		margin-bottom: 30px;
	}

	.com-faq-list__item {
		counter-increment: faq;

		&:nth-child(-n+9) {
			.com-faq-list__question {
				&:before {
					content: "Q.0" counter(faq);
				}
			}

			.com-faq-list__answer {
				&:before {
					content: "A.0" counter(faq);
				}
			}
		}

		.com-faq-list__question {
			border: 1px solid #ccc;
			position: relative;
			line-height: 1.25;
			color: #1560b4;

			@include device-content(pc) {
				padding: 10px 40px 10px 110px;
				margin-bottom: 20px;
				@include device-prop-rem(font-size, pc, 24px);
			}

			@include device-content(sp) {
				padding: 5px 20px 5px 50px;
				margin-bottom: 10px;
				@include device-prop-rem(font-size, sp, 24px * .6);
			}

			&:before {
				position: absolute;
				left: 15px;
				content: "Q." counter(faq);
				font-weight: bold;

				@include device-content(sp) {
					left: 7px;
				}
			}

			&:after {
				position: absolute;
				right: 10px;
				top: 50%;
				content: "";
				width: 25px;
				height: 25px;
				transform: translateY(-12px);
				background: url("../../img/common/icn_plus.png") no-repeat center / 25px auto;

				@include device-content(sp) {
					right: 5px;
					width: 12px;
					height: 12px;
					transform: translateY(-6px);
					background-size: 12px auto;
				}
			}

			&.-active {
				&:after {
					background: url("../../img/common/icn_minus.png") no-repeat center / 25px auto;

					@include device-content(sp) {
						background-size: 12px auto;
					}
				}
			}
		}

		.com-faq-list__answer {
			line-height: 1.75;
			position: relative;
			display: none;

			@include device-content(pc) {
				margin-top: 30px;
				padding: 0 10px 40px 110px;
			}

			@include device-content(sp) {
				margin-top: 15px;
				padding: 0 20px 20px 50px;
				@include device-prop-rem(font-size, sp, 11px);
			}

			&:before {
				position: absolute;
				content: "A." counter(faq);
				color: #1560b4;
				font-weight: bold;

				@include device-content(pc) {
					left: 15px;
					@include device-prop-rem(font-size, pc, 24px);
				}

				@include device-content(sp) {
					left: 7px;
					@include device-prop-rem(font-size, sp, 24px * .6);
				}
			}
		}
	}
}

// ============================================================
//  com-parts
// ============================================================

.com-pre-text {
	margin-bottom: 25px;
	line-height: 1.75;
}

.com-full-image {
	img {
		width: 100%;
		height: auto;
	}

	a {
		display: block;

		&:hover {
			img {
				opacity: 0.7;
			}
		}
	}
}

.com-text {
	line-height: 1.75;
}

// ============================================================
//  root-page-menu
// ============================================================

.root-page-menu {
	display: flex;
	flex-wrap: wrap;

	@include device-content(pc) {
		margin-top: 40px;
	}

	@include device-content(sp) {
		margin-top: 20px;
	}

	.root-page-menu__item {
		width: 50%;
		box-sizing: border-box;

		&:nth-child(2n-1) {
			margin-right: auto;

			@include device-content(pc) {
				padding-right: 20px;
			}

			@include device-content(sp) {
				padding-right: 8px;
			}
		}

		&:nth-child(2n) {
			@include device-content(pc) {
				padding-left: 20px;
			}

			@include device-content(sp) {
				padding-left: 8px;
			}
		}

		a {
			display: block;
			position: relative;
			border-bottom: 1px solid #0f62b8;

			@include device-content(pc) {
				padding: 40px 0;
			}

			@include device-content(sp) {
				padding: 15px 15px 15px 0;
			}

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}

			&:after {
				content: "";
				background: url("../../img/recruit/icn_arrow_right.png") no-repeat left center / 12px 19px;
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);

				@include device-content(pc) {
					width: 12px;
					height: 19px;
					right: 20px;
				}

				@include device-content(sp) {
					background-size: 6px 9px;
					width: 6px;
					height: 9px;
					right: 0;
				}
			}
		}
	}

	.root-page-menu__title {
		color: #0f63b6;
		line-height: 1.33;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
			margin-bottom: 15px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
			margin-bottom: 5px;
		}
	}

	.root-page-menu__sub-title {
		line-height: 1.33;

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 16px * .6);
			margin-bottom: 5px;
		}
	}
}

// ============================================================
//  com-img-list
// ============================================================

.com-img-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	&.-col-3 {
		margin: 0 -8px;

		@include device-content(sp) {
			margin: 0 -5px;
		}

		.com-img-list__item {
			box-sizing: border-box;
			width: 33.3333%;
			padding: 0 8px;

			@include device-content(sp) {
				padding: 0 5px;
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

// ============================================================
//  com-strong-list
// ============================================================

.com-strong-list {
	.com-strong-list__item {
		color: #0f63b7;
		line-height: 1.8;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}
}

// ============================================================
//  com-def-list
// ============================================================

.com-def-list {
	.com-def-list__title {
		color: #0f63b7;
		line-height: 1.8;
		margin-bottom: 10px;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px * .6);
		}
	}

	.com-def-list__desc {
		line-height: 1.8;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
	}
}

// ============================================================
//  scrollable
// ============================================================

.scrollable {
	@include device-content(sp) {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		&.has-scroll {
			position: relative;
			overflow: hidden;
		}

		&.has-scroll:after {
			position: absolute;
			top: 0;
			left: 100%;
			width: 50px;
			height: 100%;
			border-radius: 10px 0 0 10px / 50% 0 0 50%;
			box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
			content: '';
		}

		&.has-scroll {
			> div {
				overflow-x: auto;
			}
		}

		th {
			white-space: nowrap;
		}
	}
}

.com-search-div {
	@include device-content(pc) {
		margin-top: 40px;
	}

	@include device-content(sp) {
		margin-top: 20px;
	}
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: pointer !important;
}