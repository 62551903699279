.top-support {
	&.-en {
		.sec-header {
			@include device-content(pc) {
				margin-bottom: 30px;
			}

			@include device-content(sp) {
				margin-bottom: 15px;
			}
		}
	}
}

.top-about {
	.top-about-list {
		&.-en {
			.top-about-list__title {
				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 18px);
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 12px);
				}
			}
		}
	}
}

.search-div {
	margin-top: 80px;

	@include device-content(sp) {
		margin-top: 40px;
	}
}

.product-div {
	margin-top: 50px;

	@include device-content(sp) {
		margin-top: 25px;
	}

	&:first-child {
		margin-top: 0;
	}

	.product-div__title {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px);
		}

		color: #0f63b6;
		border-bottom: 1px solid #cbcbcb;
		padding-bottom: 15px;
		margin-bottom: 20px;

		@include device-content(sp) {
			padding-bottom: 10px;
			margin-bottom: 15px;
		}
	}

	.product-div__sub-title {
		font-weight: bold;
		line-height: 1.5;
		margin-top: 2em;
		margin-bottom: 0.5em;

		&:first-child {
			margin-top: 0;
		}

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 18px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 12px);
		}
	}

	.product-div__meta {
		@include clearfix;
		margin-bottom: 30px;

		@include device-content(sp) {
			margin-bottom: 15px;
		}
	}

	.product-div__image {
		float: left;
		margin: 0 30px 15px 0;

		@include device-content(sp) {
			margin: 0 15px 10px 0;
		}
	}

	.product-div__desc {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 16px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 10px);
		}

		line-height: 1.75;
		margin-top: 1em;

		&:first-child {
			margin-top: 0;
		}
	}
}

.product-div-image-list {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 -5px;

	.product-div-image-list__item {
		padding: 0 5px;

		img {
			width: 100%;
			height: auto;
		}
	}
}

.product-div-top {
	margin-top: 80px;

	@include device-content(sp) {
		margin-top: 40px;
	}

	.product-div-top__title {
		font-weight: bold;
		margin-bottom: 50px;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 16px);
			margin-bottom: 25px;
		}
	}

	.product-div-top__meta {
		display: flex;
	}

	.product-div-top__image {
		margin: 0 25px 30px 0;
	}
}

.product-div-top-desc {
	line-height: 1.75;
	width: 100%;

	.product-div-top-desc__title {
		border-bottom: 1px solid #cbcbcb;
		padding-bottom: 10px;
		margin-bottom: 15px;
	}

	.product-div-top-desc__text {
	}

	.product-div-top-desc__btn {
		display: block;
		margin-top: 20px;
		padding: 20px;
		text-align: center;
		box-sizing: border-box;
		color: #fff;
		background: #0f63b7;
		position: relative;

		@include device-content(pc) {
			width: 280px;
		}

		@include device-content(sp) {
			margin-top: 15px;
			padding: 10px;
		}

		&.-external {
			background: #434343;

			&:after {
				background: url("../../img/en/common/icn_external.png") no-repeat left top;
				width: 22px;
				height: 22px;
				right: 8px;
				top: 8px;
				transform: translateY(0);

				@include device-content(sp) {
					background-size: 11px 11px;
					width: 11px;
					height: 11px;
					right: 4px;
					top: 4px;
				}
			}
		}

		&:after {
			content: "";
			background: url("../../img/en/common/icn_arrow_01.png") no-repeat left top;
			width: 11px;
			height: 18px;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);

			@include device-content(sp) {
				right: 10px;
				background-size: 5px 9px;
				width: 5px;
				height: 9px;
			}
		}

		&:hover {
			text-decoration: none;
			opacity: 0.7
		}
	}
}

.product-div-link {
	.product-div-link__item {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #cbcbcb;

		@include device-content(pc) {
			display: flex;
			align-items: flex-start;
		}

		@include device-content(sp) {
			margin-bottom: 15px;
			padding-bottom: 15px;
		}
	}

	.product-div-link__title {
		line-height: 1.75;
		margin-right: auto;
		box-sizing: border-box;

		@include device-content(pc) {
			max-width: calc(100% - 280px);
			padding-right: 30px;
		}
	}

	.product-div-link__btn {
		display: block;
		padding: 20px;
		text-align: center;
		box-sizing: border-box;
		color: #fff;
		background: #0f63b7;
		position: relative;

		@include device-content(pc) {
			width: 280px;
		}

		@include device-content(sp) {
			margin-top: 15px;
			padding: 10px;
		}

		&.-external {
			background: #434343;

			&:after {
				background: url("../../img/en/common/icn_external.png") no-repeat left top;
				width: 22px;
				height: 22px;
				right: 8px;
				top: 8px;
				transform: translateY(0);

				@include device-content(sp) {
					background-size: 11px 11px;
					width: 11px;
					height: 11px;
					right: 4px;
					top: 4px;
				}
			}
		}

		&:after {
			content: "";
			background: url("../../img/en/common/icn_arrow_01.png") no-repeat left top;
			width: 11px;
			height: 18px;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);

			@include device-content(sp) {
				right: 10px;
				background-size: 5px 9px;
				width: 5px;
				height: 9px;
			}
		}

		&:hover {
			text-decoration: none;
			opacity: 0.7
		}
	}
}

.product-name {
	margin: 0 -5px;
	display: flex;
	flex-wrap: wrap;

	.product-name__item {
		padding: 0 5px;
		margin: 0 0 10px;
		min-width: 300px;

		@include device-content(sp) {
			width: 50%;
			min-width: 0;
			box-sizing: border-box;
		}

		a {
			display: block;
			padding: 20px 50px 20px 20px;
			line-height: 1.5;
			text-align: center;
			background: #0f63b7;
			color: #fff;
			position: relative;

			@include device-content(sp) {
				padding: 10px;
			}

			&:after {
				content: "";
				background: url("../../img/en/common/icn_arrow_01.png") no-repeat left top;
				width: 11px;
				height: 18px;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);

				@include device-content(sp) {
					right: 10px;
					background-size: 5px 9px;
					width: 5px;
					height: 9px;
				}
			}

			&:hover {
				text-decoration: none;
				opacity: 0.7;
			}
		}
	}
}

.page-en-product-detail {
	.business-contact {
		margin-top: 80px;

		@include device-content(sp) {
			margin-top: 40px;
		}
	}
}

.company-about-table {
	&.-en {
		th, td {
			border-bottom: 1px dotted #ccc;
		}

		th {
			width: 200px;

			@include device-content(sp) {
				width: 100px;
			}
		}
	}
}

.service-div-top {
	.service-div-top__image {
		float: left;
		margin: 0 25px 20px 0;
	}

	.service-div-top__desc {
		line-height: 1.75;

		p {
			margin-top: 1.5em;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

.service-div {
	margin-top: 50px;

	@include device-content(sp) {
		margin-top: 25px;
	}

	&:first-child {
		margin-top: 0;
	}

	.service-div__title {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 20px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 14px);
		}

		color: #0f63b6;
		border-bottom: 1px solid #cbcbcb;
		padding-bottom: 15px;
		margin-bottom: 20px;

		@include device-content(sp) {
			padding-bottom: 10px;
			margin-bottom: 15px;
		}
	}

	.service-div__desc {
		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 16px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 10px);
		}

		line-height: 1.75;
	}

	.com-action__btn {
		padding: 15px;
		width: 300px;
	}
}

.about-service {
	.about-service__title {
		line-height: 1.5;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 26px);
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 16px);
		}
	}

	.about-service__desc {
		margin-top: 40px;
		line-height: 1.75;
	}

	.about-service__image {
		margin-top: 50px;
		text-align: center;
	}
}