html,
body {
	color: #333;
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;

	@include device-content(pc) {
		font-size: get-device-value(pc, font-size);
	}

	@include device-content(sp) {
		font-size: get-device-value(sp, font-size);
	}
}

.wrapper {
	overflow: hidden;

	@include device-content(pc) {
		padding-top: 96px;
	}

	@media only screen and (max-width: 1152px) {
		padding-top: 118px;
	}

	@include device-content(sp) {
		padding-top: 34px;
		overflow: hidden;
	}
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.cnt {
	position: relative;

	@include device-content(pc) {
		max-width: 1100px;
		margin: 0 auto;
	}

	@media only screen and (max-width: 1152px) {
		padding: 0 20px;
	}

	@include device-content(sp) {
		padding: 0 10px;
	}
}

.cnt-sub {
	position: relative;

	@include device-content(pc) {
		max-width: 1000px;
		margin: 0 auto;
	}

	@media only screen and (max-width: 1152px) {
		padding: 0 20px;
	}

	@include device-content(sp) {
		padding: 0 10px;
	}
}

.cnt-flex {
	display: flex;

	&.-vc {
		align-items: center;
	}

	&.-hc {
		justify-content: center;
	}

	&.-wrap {
		flex-wrap: wrap;
	}
}

.com-header {
	position: fixed;
	left: 0;
	top: 0;
	background: #fff;
	z-index: 100;
	width: 100%;
	box-sizing: border-box;
	border-bottom: 1px solid #ccc;

	@include device-content(pc) {
		padding: 30px;
		height: 96px;
		display: table;
	}

	@media only screen and (max-width: 1152px) {
		padding: 0 20px 10px;
		height: 118px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		box-sizing: border-box;
	}

	@include device-content(sp) {
		padding: 10px;
		height: 34px;
	}

	.site-logo {
		@include device-content(pc) {
			margin-right: 20px;
			display: table-cell;
		}

		@media only screen and (max-width: 1152px) {
			display: block;
			margin-right: auto;
		}

		@include device-content(sp) {
			margin-right: auto;
		}

		img {
			@include device-content(sp) {
				width: 89px;
				height: auto;
			}
		}
	}
}

.gnav {
	margin-right: auto;
	display: table-cell;
	vertical-align: middle;

	@media only screen and (max-width: 1152px) {
		display: none;
	}

	@include device-content(sp) {
		display: none;
	}

	&.-alt {
		@include device-content(pc) {
			display: none;
		}

		@media only screen and (max-width: 1152px) {
			display: block;
			width: 100%;
			margin-right: 0;
		}

		@include device-content(sp) {
			display: none;
		}
	}

	.gnav-list {
		display: table;
		align-items: center;

		@media only screen and (max-width: 1152px) {
			width: 100%;
		}

		.gnav-list__item {
			width: 130px;
			display: table-cell;
			position: relative;
			text-align: center;

			&:hover {
				.gnav-box {
					display: block;
				}
			}

			a {
				color: #363636;
				display: block;
				position: relative;

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}

			&.-has-children {
				a {
					&:after {
						content: "";
						position: absolute;
						display: block;
						background: url("../../img/common/icn_arrow_down_01.png") no-repeat center bottom;
						width: 14px;
						height: 7px;
						left: 50%;
						bottom: -17px;
						transform: translate(-50%, 0);
					}
				}
			}

			&.-about {
				.gnav-box__title {
					background: url("../../img/common/bg_nav_about.jpg");
				}
			}

			&.-company {
				.gnav-box__title {
					background: url("../../img/common/bg_nav_company.jpg");
				}
			}

			&.-product {
				.gnav-box__title {
					background: url("../../img/common/bg_nav_product.jpg");
				}
			}

			&.-recruit {
				.gnav-box__title {
					background: url("../../img/common/bg_nav_recruit.jpg");
				}
			}
		}
	}

	.gnav-box {
		display: none;
		position: absolute;
		top: 1em;
		width: 740px;
		left: -130px;
		padding-top: calc(1em + 40px);

		&.-right {
			left: auto;
			right: -130px;
		}

		.gnav-box__inner {
			background: #000;
			padding: 20px;
			display: flex;
		}

		.gnav-box__title {
			color: #fff;
			font-weight: bold;
			width: 293px;
			height: 280px;
			display: flex;
			margin-right: 25px;
			justify-content: center;
			align-items: center;
			@include device-prop-rem(font-size, pc, 30px);
		}

		.gnav-box-nav-list {
			text-align: left;

			li {
				margin-top: 10px;
				line-height: 1.5;

				&:first-child {
					margin-top: 0;
				}

				a {
					color: #fff;
				}
			}
		}
	}
}

.snav {
	display: table-cell;
	vertical-align: middle;
	position: relative;

	@media only screen and (max-width: 1152px) {
		display: block;
	}

	.header-search {
		position: absolute;
		z-index: 100;
		top: 40px;
		right: 42px;
		letter-spacing: -0.4em;
		width: 240px;

		@media only screen and (max-width: 1152px) {
			top: 34px;
		}

		@include device-content(sp) {
			top: 14px;
			right: 24px;
			width: 120px;
		}

		.header-search__input {
			border: 1px solid #ccc;
			letter-spacing: normal;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			box-sizing: border-box;
			vertical-align: middle;
			width: 200px;
			background: #eee;
			outline: none;

			@include device-content(sp) {
				height: 20px;
				width: 100px;
				line-height: 20px;
				padding: 0 5px;
			}
		}

		.header-search__btn {
			border: 1px solid #000;
			letter-spacing: normal;
			height: 40px;
			width: 40px;
			line-height: 40px;
			vertical-align: middle;
			box-sizing: border-box;
			background: #000 url("../../img/common/icn_search_02.png") no-repeat center;
			outline: none;

			@include device-content(sp) {
				width: 20px;
				height: 20px;
				line-height: 20px;
				background-size: 11px auto;
			}
		}
	}

	.snav-list {
		display: flex;
		float: right;
		align-items: center;

		@include device-content(sp) {
			justify-content: flex-end;
		}

		.snav-list__item {
			margin-left: 15px;
			white-space: nowrap;

			a {
				color: #363636;
				display: block;
				position: relative;

				span {
					@include device-content(sp) {
						display: none;
					}
				}

				&:hover {
					text-decoration: none;
					opacity: 0.6;
				}
			}

			&.-contact {
				a {
					color: #0f63b7;

					@include device-content(pc) {
						padding-left: 26px;
					}

					@include device-content(sp) {
						padding-left: 13px;
					}

					&:before {
						content: "";
						background: url("../../img/common/icn_mail_01.png") no-repeat left top;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);

						@include device-content(pc) {
							background-size: 19px auto;
							width: 19px;
							height: 12px;
						}

						@include device-content(sp) {
							background-size: 13px auto;
							width: 13px;
							height: 9px;
						}
					}
				}
			}

			&.-lang {
				&.-en {
					a {
						&:before {
							background-image: url("../../img/common/icn_en.png");
						}
					}
				}

				&.-jpn {
					a {
						&:before {
							background-image: url("../../img/en/common/icn_jpn.png");
						}
					}
				}

				a {
					@include device-content(pc) {
						padding-left: 37px;
					}

					@include device-content(sp) {
						padding-left: 13px;
					}

					&:before {
						content: "";
						background: no-repeat left top;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);

						@include device-content(pc) {
							background-size: 27px auto;
							width: 27px;
							height: 18px;
						}

						@include device-content(sp) {
							background-size: 13px auto;
							width: 13px;
							height: 9px;
						}
					}
				}
			}

			&.-search {
				@include device-content(pc) {
					margin-left: 20px;
				}

				&.-on {
					a {
						&:before {
							content: "";
							background: url("../../img/common/icn_close_02.png") no-repeat left top;

							@include device-content(sp) {
								width: 10px;
								height: 10px;
								background-size: 10px auto;
							}
						}
					}
				}

				a {
					@include device-content(pc) {
						padding-left: 21px;
					}

					@include device-content(sp) {
						padding-left: 10px;
					}

					&:before {
						content: "";
						background: url("../../img/common/icn_search_01.png") no-repeat left top;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);

						@include device-content(pc) {
							background-size: 21px auto;
							width: 21px;
							height: 22px;
						}

						@include device-content(sp) {
							background-size: 10px auto;
							width: 10px;
							height: 11px;
						}
					}
				}
			}

			&.-menu {
				@include device-content(pc) {
					margin-left: 25px;
				}

				a {
					@include device-content(pc) {
						padding-left: 28px;
					}

					@include device-content(sp) {
						padding-left: 14px;
					}

					&:before {
						content: "";
						background: url("../../img/common/icn_menu.png") no-repeat left top;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);

						@include device-content(pc) {
							background-size: 28px auto;
							width: 28px;
							height: 24px;
						}

						@include device-content(sp) {
							background-size: 14px auto;
							width: 14px;
							height: 12px;
						}
					}
				}
			}
		}
	}
}

.com-body {
	@include device-content(pc) {
		padding-bottom: 200px;
	}

	@include device-content(sp) {
		padding-bottom: 50px;
	}
}

.com-footer {
	background: #282828;
	color: #fff;

	.cnt {
		@include device-content(sp) {
			padding: 0;
		}
	}

	@include device-content(pc) {
		padding: 50px 0;
	}

	@include device-content(sp) {
		padding: 0 0 25px;
	}
}

.page-top {
	position: absolute;
	top: -81px;
	right: -70px;

	@include device-content(sp) {
		display: none;
	}

	a {
		&:hover {
			opacity: 0.6;
		}
	}
}

.footer-contact {
	@include device-content(pc) {
		display: none;
	}

	a {
		background: #0f62b8;
		display: block;
		text-align: center;
		color: #fff;
		padding: 15px 0;
		@include device-prop-rem(font-size, sp, 11px);

		span {
			display: inline-block;
			position: relative;
			background: url("../../img/common/icn_mail_02.png") no-repeat left center;
			background-size: 9px auto;
			padding-left: 15px;
		}
	}
}

.fnav {
	@include device-content(sp) {
		display: none;
	}

	.fnav-list {
		padding: 0 40px 60px;
		box-sizing: border-box;
		width: 100%;
		border-bottom: 1px solid #505050;
		display: table;

		li {
			display: table-cell;
			min-width: 140px;
			line-height: 1.5;

			a {
				color: #fff;
			}

			li {
				display: block;
				@include device-prop-rem(font-size, pc, 14px);
				margin-top: 8px;
			}

			&.primary {
				li {
					@include device-prop-rem(font-size, pc, 16px);
					margin-top: 12px;
				}
			}
		}
	}
}

.fsnav {
	@include device-content(pc) {
		display: none;
	}

	border-bottom: 1px solid #505050;

	.fsnav-list {
		display: flex;
		justify-content: center;
		padding: 16px 0 10px;

		li {
			text-align: center;
			padding: 0 8px;
			line-height: 1.25;
			border-left: 1px solid #838383;

			&:first-child {
				border-left: 0;
				padding-left: 0;
			}

			a {
				color: #fff;
				@include device-prop-rem(font-size, sp, 9px);
			}
		}
	}
}

.copyright {
	text-align: center;

	@include device-content(pc) {
		margin-top: 40px;
	}

	@include device-content(sp) {
		margin-top: 20px;
	}

	.copyright__logo {
		@include device-content(sp) {
			width: 118px;
			height: auto;
		}
	}

	.copyright__text {
		margin-top: 15px;

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 9px);
		}
	}
}

.fixed-nav {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 110;
	height: 100%;
	background: rgba(34, 34, 34, 0.95);

	> .cnt {
		max-width: 1200px;
		width: auto;
	}

	@include device-content(pc) {
		display: flex;
		align-items: center;
	}

	@include device-content(sp) {
		padding-top: 30px;
	}

	.fixed-nav__title {
		color: #fff;
		text-align: center;
		font-weight: bold;

		@include device-content(pc) {
			@include device-prop-rem(font-size, pc, 40px);
			margin-bottom: 70px;
		}

		@include device-content(sp) {
			@include device-prop-rem(font-size, sp, 20px);
			margin-bottom: 35px;
		}
	}

	.fixed-nav-list {
		@include device-content(pc) {
			display: table;
			width: 100%;
		}

		@include device-content(sp) {
			display: flex;
			flex-wrap: wrap;
			height: calc(100vh - 85px);
			padding-left: 20px;
			overflow-y: scroll;
		}

		li {
			line-height: 1.5;
			padding: 0 8px;

			@include device-content(pc) {
				display: table-cell;
				margin: 0 10px;
				min-width: 140px;
				@include device-prop-rem(font-size, pc, 20px);
			}

			@include device-content(sp) {
				width: 50%;
				box-sizing: border-box;
				padding: 0 20px;
				margin-bottom: 20px;
				@include device-prop-rem(font-size, sp, 13px);
			}

			a {
				color: #fff;
			}

			li {
				display: block;
				margin: 0;
				width: 100%;
				padding: 0;
				min-width: 0;
				margin-top: 8px;

				@include device-content(pc) {
					@include device-prop-rem(font-size, pc, 18px);
				}

				@include device-content(sp) {
					@include device-prop-rem(font-size, sp, 11px);
				}
			}

			&.primary {
				li {
					margin-top: 12px;

					@include device-content(pc) {
						@include device-prop-rem(font-size, pc, 20px);
					}

					@include device-content(sp) {
						@include device-prop-rem(font-size, sp, 13px);
					}
				}
			}
		}
	}

	.fixed-nav-contact {
		margin-top: 100px;
		text-align: center;

		.fixed-nav-contact__btn {
			display: inline-block;
			color: #fff;
			width: 500px;
			box-sizing: border-box;
			padding: 30px;
			@include device-prop-rem(font-size, pc, 22px);
			background: #0f62b6 url("../../img/common/icn_mail_02.png") no-repeat 30px center;

			&:hover {
				text-decoration: none;
				opacity: 0.6;
			}
		}
	}

	.fixed-nav__close {
		position: absolute;
		z-index: 10;
		display: inline-block;
		background: url("../../img/common/icn_close.png") no-repeat left center;

		@include device-content(pc) {
			width: 23px;
			height: 23px;
			right: 33px;
			top: 35px;
		}

		@include device-content(sp) {
			width: 12px;
			height: 12px;
			background-size: 12px auto;
			right: 12px;
			top: 12px;
		}
	}
}